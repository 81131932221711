import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import SimpleBar from "simplebar-react";


const Overview = ({img, text, title,data}) => {

    const video_data = data?.video_id;

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };

    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };

    return (
        <StyledOverview className='video pt-200 pb-200'>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title text={data?.title} color={'#FFFFFF'}  />
                    </Col>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div onClick={() => handelOpen(true, video_data)} key={0}>
                            <div className="video__image ">
                                <Img src={data?.image ? data?.image : '/images/dynamic/home/1.jpg'} />
                            </div>

                            <div className="video__button ">
                                <div className="svg-container">
                                    <svg id="Group_20003" data-name="Group 20003" xmlns="http://www.w3.org/2000/svg"
                                         width="140" height="140" viewBox="0 0 140 140">
                                        <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(140 140) rotate(180)"
                                           fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                            <circle id="circle-border" cx="70" cy="70" r="70" stroke="#FFFDFB" stroke-width="1" />
                                            <circle id="hover-color" cx="70" cy="70" r="69.5" stroke="none" fill="" />
                                        </g>
                                        <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z"
                                              transform="translate(83 55) rotate(90)" fill="#fffdfb" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* modal video */}
            <Modal
                show={show}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <Modal.Body>
                    <SimpleBar style={{ maxHeight: '100vh', width: '90vw' }}>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">
                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none" />
                                            <circle cx="22" cy="22" r="21.5" fill="none" />
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none" />
                                            <circle cx="22" cy="22" r="21.5" fill="none" />
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1" />
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1" />
                                        </g>
                                    </svg>
                                </div>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <ModalVideo
                                        channel='youtube'
                                        isOpen={open}
                                        videoId={videoId}
                                        onClose={handleCloseModal}
                                    />
                                </div>
                            </Row>
                        </Container>
                    </SimpleBar>
                </Modal.Body>
            </Modal>
        </StyledOverview>
    );
};

const StyledOverview = styled.section`
  //padding-top: 200px;
  //transform: translateY(-20%);
  position: relative;
  

  
  
.video__image{
  position: relative;
  padding-top: calc(470 / 770 * 100%);
  overflow: hidden;
  transform: translateY(-85px);
  
  img{
    top: 0;
    z-index: 2;
  }
  
}
  
  .video__button{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 99999;
  }

  .svg-container {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    svg{
      width: 100%;
      height: 100%;
    }
  }

  #hover-color {
    fill: transparent;
    transition: fill 0.8s;
  }
  #circle-border{
    transition: all 1s;
  }


  .svg-container:hover {

    #hover-color {
      fill: #AC8B7C;
      animation: fillAnimation 0.8s forwards;
    }
    #circle-border{
      stroke-width: 0;
    }
  }


  .svg-container:not(:hover) #hover-color {
    fill: transparent;
    animation: reverseFillAnimation 0.8s backwards;
  }

  @keyframes fillAnimation {
    0% {
      r: 0px;
    }
    100% {
      r: 70px;
    }
  }
  @keyframes reverseFillAnimation {
    0% {
      r: 69.5px;
    }
    100% {
      r: 0px;
    }
  }
  
  
  
  @media(max-width: 767px){
    //transform: translateY(-35%);
    
    .svg-container{
      height: 70px;
      width: 70px;
    }
    .video__image{


      img{
        top: 20px;
      }

    }
  }
  
  
`;

export default Overview;
