import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

import * as Website from './animations/website.json'
import Lottie from 'react-lottie';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchGlobal} from "../api/redux/menu";

// import {gsap} from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
const Message = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL
        dispath(fetchGlobal([api_url]))
    }, [])


    let getPost = useSelector(state => state.social)
    //
    let social_link=getPost?.data?.[0]?.links;
    let phone=getPost?.data?.[0]?.contact?.phone;
    console.log(social_link);

    // console.log(social_link);
    const website = {
        loop: true,
        autoplay: true,
        animationData: Website,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <StyledMessage data-scroll-section className={`title `}
        >
            <div className={'message_wrapper'}>
                <div id={'click_message'}
                     // ref={searchClickRef}
                     className=" social_bar">
                    {/*<div className="overlay_message"></div>*/}
                  <div className="chat social_bar__single">
                        <a href={social_link?.messenger_code} target={'_blank'}>
                            <svg id="Component_43_1" data-name="Component 43 – 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <defs>
                                    <clipPath id="clip-path3">
                                        <circle id="Ellipse_612" data-name="Ellipse 612" cx="25" cy="25" r="25" transform="translate(1272 23)" fill="#f9f8f5"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_343" data-name="Mask Group 343" transform="translate(-1272 -23)" clip-path="url(#clip-path3)">
                                    <circle id="Ellipse_12" data-name="Ellipse 11" cx="25" cy="25" r="25" transform="translate(1272 23)" fill="#f9f8f5"/>
                                    <circle className={'circ'} id="Ellipse_13" data-name="Ellipse 11" cx="25" cy="25" r="0" transform="translate(1272 23)" fill="#AC8B7C"/>

                                    <g id="Group_19872" data-name="Group 19872" transform="translate(-0.168 1.022)">
                                        <g id="Icon" transform="translate(1908.125 -7.663)">
                                            <path id="Path_9527" data-name="Path 9527" d="M-505.252,192.455a.227.227,0,0,1-.292-.011.228.228,0,0,1-.038-.291l2.272-3.536a.76.76,0,0,1,.483-.332.756.756,0,0,1,.575.111l1.247.832a.758.758,0,0,0,.788.032l2.185-1.214a.228.228,0,0,1,.278.045.229.229,0,0,1,.022.28l-2.118,3.176a.757.757,0,0,1-1.006.238l-1.6-.913a.757.757,0,0,0-.841.06l-1.958,1.523Z" transform="translate(-109.507 -135.355)" fill="#2d2d2d" fill-rule="evenodd"/>
                                        </g>
                                        <g id="Group_19871" data-name="Group 19871" transform="translate(1286 37)">
                                            <g id="Group_198666" data-name="Group 198666">
                                                <path id="Path_9530" data-name="Path 9528" d="M11.168,0C5.01,0,0,4.476,0,9.978a9.171,9.171,0,0,0,1.691,5.281C1.25,16.64.453,19.252.445,19.279a.356.356,0,0,0,.116.376.469.469,0,0,0,.425.091L5.412,18.53a12.161,12.161,0,0,0,5.756,1.426c6.158,0,11.168-4.476,11.168-9.978S17.327,0,11.168,0Zm0,19.188a11.207,11.207,0,0,1-5.469-1.4.468.468,0,0,0-.228-.058.485.485,0,0,0-.126.017L1.48,18.809c.284-.926.794-2.582,1.09-3.5a.351.351,0,0,0-.052-.315A8.483,8.483,0,0,1,.859,9.978C.859,4.9,5.484.768,11.168.768S21.477,4.9,21.477,9.978,16.852,19.188,11.168,19.188Z" fill="#2d2d2d"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="chat social_bar__single">
                        <a href={social_link?.whatsapp} target={'_blank'}>
                            <svg id="Mask_Group_10" data-name="Mask Group 10" xmlns="http://www.w3.org/2000/svg"  width="50" height="50" viewBox="0 0 50 50">
                                <defs>
                                    <clipPath id="clip-path2">
                                        <circle id="Ellipse_610" data-name="Ellipse 610" cx="25" cy="25" r="25" fill="#f9f8f5"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_10-2" data-name="Mask Group 10" clip-path="url(#clip-path2)">
                                    <circle id="Ellipse_11" data-name="Ellipse 17" cx="25" cy="25" r="25" fill="#f9f8f5"/>
                                    <circle className={'circ'} id="Ellipse_17" data-name="Ellipse 11" cx="25" cy="25" r="0" fill="#AC8B7C"/>


                                    <g id="Group_19867" data-name="Group 19867" transform="translate(13.832 13.832)">
                                        <g id="Group_19866" data-name="Group 19866">
                                            <path id="Path_9528" data-name="Path 9528" d="M11.168,0A11.17,11.17,0,0,0,1.691,17.08C1.25,18.625.453,21.549.445,21.579a.43.43,0,0,0,.541.523l4.427-1.362A11.169,11.169,0,1,0,11.168,0Zm0,21.477A10.283,10.283,0,0,1,5.7,19.909a.43.43,0,0,0-.228-.065.437.437,0,0,0-.126.019L1.48,21.053c.284-1.036.794-2.89,1.09-3.922a.431.431,0,0,0-.052-.353,10.31,10.31,0,1,1,8.651,4.7Z" fill="#2d2d2d"/>
                                            <path id="Path_9529" data-name="Path 9529" d="M20.33,17.077c-.6-.335-1.117-.671-1.491-.916-.286-.187-.493-.322-.645-.4a.742.742,0,0,0-.867.061.327.327,0,0,0-.041.05A4.592,4.592,0,0,1,16.1,17.2,7.866,7.866,0,0,1,14.1,15.9a4.349,4.349,0,0,1-1.589-2.008c.592-.61.806-.993.806-1.429a9.244,9.244,0,0,0-1.239-2.518c-.19-.19-.619-.22-1.275-.089a.33.33,0,0,0-.167.089,4.261,4.261,0,0,0-1.054,4.268c.969,2.519,3.456,5.447,6.626,5.922a6.871,6.871,0,0,0,1.014.081,3.007,3.007,0,0,0,3.276-2.8A.325.325,0,0,0,20.33,17.077ZM16.3,19.489c-3.353-.5-5.465-3.825-6.114-5.511a3.6,3.6,0,0,1,.841-3.5,2.464,2.464,0,0,1,.6-.042,11.732,11.732,0,0,1,1.027,2.027c0,.171-.056.41-.721,1.076a.325.325,0,0,0-.1.231c0,1.71,3.608,4.083,4.247,4.083.556,0,1.28-.934,1.693-1.543a.31.31,0,0,1,.121.038c.118.059.321.192.579.361.34.222.792.518,1.326.824C19.566,18.7,18.888,19.878,16.3,19.489Z" transform="translate(-3.751 -3.828)" fill="#2d2d2d"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="chat social_bar__single">
                        <a href={`tel:${phone}`}>
                            <svg id="Mask_Group_10" data-name="Mask Group 10" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <defs>
                                    <clipPath id="clip-path5">
                                        <circle id="Ellipse_610" data-name="Ellipse 610" cx="25" cy="25" r="25" fill="#f9f8f5"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_10-2" data-name="Mask Group 10" clip-path="url(#clip-path5)">
                                    <circle id="Ellipse_11" data-name="Ellipse 11" cx="25" cy="25" r="25" fill="#f9f8f5"/>
                                    <circle className={'circ'} id="Ellipse_609" data-name="Ellipse 609" cx="25" cy="25" r="0" fill="#ac8b7c"/>
                                    <g id="Group_19867" data-name="Group 19867" transform="translate(13.832 15.832)">
                                        <g id="Layer_11" data-name="Layer 11" >
                                            <path id="Path_9532" data-name="Path 9532" d="M19.8,10.875c-.1-4.8-2.5-10.1-8.8-10.1s-8.7,5.4-8.8,10.1a2.228,2.228,0,0,0-1.9,2.1v.8a2.22,2.22,0,0,0,2.2,2.2H3a1.11,1.11,0,0,0,1.1-1.1h0v-2.8a1.028,1.028,0,0,0-1-1.1c.1-4.3,2.2-9.1,7.8-9.1s7.7,4.8,7.8,9.1a1.164,1.164,0,0,0-1,1.1v2.8a1.11,1.11,0,0,0,1.1,1.1h.8a3.778,3.778,0,0,1-3.8,3.2H13.2a1.384,1.384,0,0,0-1.3-1.1h-2a1.324,1.324,0,0,0-1.3,1.3v.4a1.324,1.324,0,0,0,1.3,1.3h2a1.287,1.287,0,0,0,1.3-1.1h2.6a4.919,4.919,0,0,0,4.9-4.6,2.138,2.138,0,0,0,.9-1.8v-.5A2.078,2.078,0,0,0,19.8,10.875Zm-16.6,4a.215.215,0,0,1-.2.2H2.5a1.155,1.155,0,0,1-1.2-1.2v-.8a1.155,1.155,0,0,1,1.2-1.2H3a.215.215,0,0,1,.2.2h0Zm9.2,5a.43.43,0,0,1-.4.4H10a.43.43,0,0,1-.4-.4v-.4a.43.43,0,0,1,.4-.4h2a.43.43,0,0,1,.4.4Zm6.6-4.8a.215.215,0,0,1-.2-.2h0v-2.8a.215.215,0,0,1,.2-.2h.5a1.155,1.155,0,0,1,1.2,1.2v.8a1.1,1.1,0,0,1-.6,1c-.1,0-.1,0-.2.1a.9.9,0,0,1-.5.1Z" fill="#2d2d2d"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>

                    <div className="svg-button social_bar__single">
                        <Lottie options={website} height={30} width={30}/>
                    </div>

                </div>
            </div>
        </StyledMessage>

    )
};


const StyledMessage = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translate(50px, -30px);
  z-index: 990;

  .chat {
    opacity: 0;
    transition: .6s ease-in-out;
  }
  .social_bar{
    &__single{
      margin-top: 10px;
     
      img{
        cursor: pointer;
        
      }
      
      svg{
        
        g{
          .circ{
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            
            //r: 25px
          }
        }
      }
      &:hover{
        svg{         
          g{
            .circ{             
              r: 25px;
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }
        }
      }      
    }
    &:hover{
      .chat{
        opacity: 1;
        transition: .6s ease-in-out;
        }
    }
  }
  
  .svg-button{
    height: 50px;
    width: 50px;
    background: #F9F8F5;
    border-radius: 50%;
    padding: 10px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));


  }
  
  @media(max-width: 767px){
    right: 0;
    transform: translate(-30px, -30px);
    left: unset;   
  }

`;

export default Message;














