import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {CSSPlugin, gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import 'react-toastify/dist/ReactToastify.css';

import {SplitText} from "gsap/SplitText";

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Media from "./pages/media";
import Menu from "./components/Menu";
import Contact from "./pages/contact";
import Footer from "./components/Footer";
import Sticky from "./components/Sticky";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {ToastContainer} from "react-toastify";
import Career from "./pages/Career";

function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CSSPlugin, DrawSVGPlugin, SplitText);


    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, []);
    let scroller = ScrollSmoother.create({});

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }
        window.scroll(0, 0)
        scroller.effects('.new-parallax', {
            speed: 1,
            lag: 0.1,
            top: '100px',
            bottom: '0px'
        });
    }, [location.pathname,scroller])


    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()
    // page transition
    PageTransition()


    //
    // useEffect(() => {
    //     if (window.innerWidth > 768) {
    //         // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    //
    //         let smoother = ScrollSmoother.create({
    //             smooth: 2,
    //             effects: true
    //         });
    //         scroller.effects('.box', {
    //             speed: 1.1,
    //             lag: 0,
    //             top: '0px',
    //             bottom: '0px'
    //         });
    //     }
    // }, [location.pathname]);

    // offset
    // container padding
    const [offset,setOffset] = useState(0)


    useEffect(() => {
        if (window.innerWidth > 991) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 991) {
                setOffset(document.querySelector('.container').offsetLeft + 15)
            }
        })
    }, [location.pathname])





    return (
        <>
            <GlobalStyle/>
            <ToastContainer
                position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            {/*page transition */}
            <div className="page-loader">
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 50.531 50.548">
                    <g id="Group_19837" data-name="Group 19837" transform="translate(-1135.608 -86.953)">
                        <g id="Group_19837-2" data-name="Group 19837" transform="translate(1135.608 86.953)">
                            <path id="Path_9494" data-name="Path 9494"
                                  d="M1135.642,137.5l-.034-50.515,50.5-.033.033,50.515Zm1.269-49.214.032,47.91,47.892-.031-.031-47.91Z"
                                  transform="translate(-1135.608 -86.953)" fill="#fff"></path>
                        </g>
                        <g id="Group_19839" data-name="Group 19839" transform="translate(1140.251 96.955)">
                            <path id="Path_9495" data-name="Path 9495"
                                  d="M1175.4,198.729V172.17l5.512,3.6V188.02l4.838-4.874h6.338l-4.95,5.025,4.892,10.559h-6.152l-3.091-5.946-1.837,1.575v4.371Z"
                                  transform="translate(-1175.271 -171.949)" fill="#fff"></path>
                            <path id="Path_9496" data-name="Path 9496"
                                  d="M1328.712,170.755v26.7h5.663v-9.369a1.842,1.842,0,0,1,1.987-1.725c1.688,0,1.8,1.8,1.8,1.8v9.293h5.738v-9.293c0-5.887-4.688-8.513-9.527-4.987v-8.831Z"
                                  transform="translate(-1310.19 -170.704)" fill="#fff"></path>
                            <path id="Path_9497" data-name="Path 9497" d="M1469.561,197.113V170.329l5.664,3.577v23.208Z"
                                  transform="translate(-1434.143 -170.329)" fill="#fff"></path>
                            <g id="Group_19838" data-name="Group 19838" transform="translate(0 29.127)">
                                <path id="Path_9498" data-name="Path 9498"
                                      d="M1175.005,417.847v.542h.542v.313h-.542v1.219c0,.28.073.439.284.439a.648.648,0,0,0,.219-.028l.017.313a.958.958,0,0,1-.335.056.518.518,0,0,1-.408-.173.939.939,0,0,1-.146-.593V418.7h-.322v-.313h.322v-.42Z"
                                      transform="translate(-1174.313 -417.282)" fill="#fff"></path>
                                <path id="Path_9499" data-name="Path 9499"
                                      d="M1188.14,413.563h.378v1.415h.009a.746.746,0,0,1,.271-.294.728.728,0,0,1,.387-.112c.28,0,.726.187.726.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.557.557,0,0,0-.507.406.564.564,0,0,0-.026.2v1.364h-.378Z"
                                      transform="translate(-1186.481 -413.512)" fill="#fff"></path>
                                <path id="Path_9500" data-name="Path 9500"
                                      d="M1207.13,423.227a.693.693,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                      transform="translate(-1202.876 -420.915)" fill="#fff"></path>
                                <path id="Path_9501" data-name="Path 9501"
                                      d="M1232.473,423.867a1.007,1.007,0,0,0,.5.159c.271,0,.4-.145.4-.336s-.108-.3-.383-.411c-.378-.149-.554-.369-.554-.64a.666.666,0,0,1,.718-.663.905.905,0,0,1,.507.14l-.09.3a.761.761,0,0,0-.425-.135.309.309,0,0,0-.344.308c0,.187.12.271.391.388.357.145.546.341.546.677a.684.684,0,0,1-.774.682,1.112,1.112,0,0,1-.584-.159Z"
                                      transform="translate(-1225.417 -420.915)" fill="#fff"></path>
                                <path id="Path_9502" data-name="Path 9502"
                                      d="M1247.826,415.183a.236.236,0,0,1-.241.252.233.233,0,0,1-.228-.252.241.241,0,0,1,.236-.257A.238.238,0,0,1,1247.826,415.183Zm-.421,2.9v-2.261h.378v2.261Z"
                                      transform="translate(-1238.595 -414.711)" fill="#fff"></path>
                                <path id="Path_9503" data-name="Path 9503"
                                      d="M1256.81,423.951a1.4,1.4,0,0,1-.3,1.037,1.084,1.084,0,0,1-.761.271,1.258,1.258,0,0,1-.692-.187l.094-.318a1.067,1.067,0,0,0,.61.182c.387,0,.67-.22.67-.794v-.248h-.009a.726.726,0,0,1-.662.378.984.984,0,0,1-.885-1.1c0-.766.46-1.2.941-1.2a.687.687,0,0,1,.649.388h.009l.013-.341h.335c-.013.159-.017.346-.017.617Zm-.378-1.037a.586.586,0,0,0-.021-.182.55.55,0,0,0-.529-.439c-.361,0-.619.332-.619.855,0,.444.211.813.615.813a.552.552,0,0,0,.524-.42.773.773,0,0,0,.03-.22Z"
                                      transform="translate(-1245.215 -420.915)" fill="#fff"></path>
                                <path id="Path_9504" data-name="Path 9504"
                                      d="M1275.854,422.634c0-.238,0-.425-.017-.612h.335l.021.374h.009a.763.763,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.637.637,0,0,0-.026.191v1.359h-.378Z"
                                      transform="translate(-1263.658 -420.915)" fill="#fff"></path>
                                <path id="Path_9505" data-name="Path 9505"
                                      d="M1304.235,423.133a1.068,1.068,0,0,1-1.036,1.2,1.042,1.042,0,0,1-1-1.163,1.068,1.068,0,0,1,1.032-1.2A1.034,1.034,0,0,1,1304.235,423.133Zm-1.646.023c0,.5.258.869.627.869s.632-.369.632-.878c0-.383-.176-.864-.623-.864S1302.589,422.732,1302.589,423.157Z"
                                      transform="translate(-1286.86 -420.915)" fill="#fff"></path>
                                <path id="Path_9506" data-name="Path 9506"
                                      d="M1321.334,416.5v-1.948h-.288v-.313h.288v-.107a1.146,1.146,0,0,1,.241-.789.692.692,0,0,1,.507-.21.8.8,0,0,1,.322.065l-.052.318a.514.514,0,0,0-.241-.051c-.322,0-.4.3-.4.654v.121h.5v.313h-.5V416.5Z"
                                      transform="translate(-1303.444 -413.135)" fill="#fff"></path>
                                <path id="Path_9507" data-name="Path 9507"
                                      d="M1340.285,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.578,1.578,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.067,1.067,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                      transform="translate(-1320.057 -420.915)" fill="#fff"></path>
                                <path id="Path_9508" data-name="Path 9508"
                                      d="M1357.183,422.363l.3.486c.082.126.146.238.215.364h.009c.069-.131.137-.248.21-.369l.288-.481h.412l-.709,1.093.726,1.168h-.426l-.309-.509c-.082-.131-.15-.257-.223-.393h-.009c-.069.135-.137.257-.219.393l-.3.509h-.417l.739-1.154-.7-1.107Z"
                                      transform="translate(-1334.844 -421.256)" fill="#fff"></path>
                                <path id="Path_9509" data-name="Path 9509"
                                      d="M1375.483,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.761.761,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                      transform="translate(-1349.913 -420.915)" fill="#fff"></path>
                                <path id="Path_9510" data-name="Path 9510"
                                      d="M1390.081,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.077,1.077,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                      transform="translate(-1363.88 -420.915)" fill="#fff"></path>
                                <path id="Path_9511" data-name="Path 9511" d="M1408.921,413.563h.378v3.317h-.378Z"
                                      transform="translate(-1380.777 -413.512)" fill="#fff"></path>
                                <path id="Path_9512" data-name="Path 9512" d="M1417.375,413.563h.378v3.317h-.378Z"
                                      transform="translate(-1388.217 -413.512)" fill="#fff"></path>
                                <path id="Path_9513" data-name="Path 9513"
                                      d="M1424.936,423.227a.693.693,0,0,0,.713.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                      transform="translate(-1394.553 -420.915)" fill="#fff"></path>
                                <path id="Path_9514" data-name="Path 9514"
                                      d="M1443.651,422.634c0-.238,0-.425-.017-.612h.335l.021.374H1444a.762.762,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.633.633,0,0,0-.026.191v1.359h-.378Z"
                                      transform="translate(-1411.326 -420.915)" fill="#fff"></path>
                                <path id="Path_9515" data-name="Path 9515"
                                      d="M1464.039,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.76.76,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                      transform="translate(-1427.846 -420.915)" fill="#fff"></path>
                                <path id="Path_9516" data-name="Path 9516"
                                      d="M1478.6,423.227a.694.694,0,0,0,.714.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                      transform="translate(-1441.78 -420.915)" fill="#fff"></path>
                                <path id="Path_9517" data-name="Path 9517"
                                      d="M1496.3,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.512,0Z"
                                      transform="translate(-1457.67 -433.901)" fill="#fff"></path>
                                <path id="Path_9518" data-name="Path 9518"
                                      d="M1503.71,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                      transform="translate(-1464.195 -433.901)" fill="#fff"></path>
                                <path id="Path_9519" data-name="Path 9519"
                                      d="M1511.125,437.026a.269.269,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                      transform="translate(-1470.721 -433.901)" fill="#fff"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                {/*<img className={'logo'} src="/images/static/logo.svg" alt=""/>*/}
            </div>
            <Sticky/>
            <Menu/>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/" component={Home} offset={offset}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/media" component={Media}/>
                        <Route exact path={`/project`} component={Project}/>
                        <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                        <Route exact path={`/career`} component={Career}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer/>
                </div>
            </div>
        </>


    );
}

export default App;
