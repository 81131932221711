import React, {useEffect, useState} from 'react';
import {Img} from "../Img";
// import img1 from "../../public/images/static/image1.jpg";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import {transition} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import reactHtmlParser from "react-html-parser";
import Title from "../Title";

const Project = ({img, data,photoGallery}) => {
    return (
        <StyledProject className='project'>
            <div className='project__img'>
                <Img src={img}/>
                <div className='project__img__content'>
                    {
                        data?.detail_info?.list?.length > 0 ?
                            <>
                                <div className="date">
                                    <p>{data?.detail_info?.date}</p>
                                    <p className={'fade-up'}>{"("+data?.detail_info?.list?.length+")"}</p>
                                </div>
                            </>  :
                            <p>{data?.detail_info?.date}</p>
                    }

                    <Title text={ReactHtmlParser(data?.post_title)} color={'#FFFEFB'} fontSize={'24'} lineHeight={'32'} />
                </div>

                {
                    data?.detail_info?.video_id ?
                        <div className="video__box__inner__click">
                            <div className="svg-container">
                                <svg id="Group_20003" data-name="Group 20003" xmlns="http://www.w3.org/2000/svg"
                                     width="140" height="140" viewBox="0 0 140 140">
                                    <g id="Ellipse_10" data-name="Ellipse 10" transform="translate(140 140) rotate(180)"
                                       fill="none" stroke="#fffdfb" stroke-linecap="round" stroke-width="1">
                                        <circle id="circle-border" cx="70" cy="70" r="70" stroke="#FFFDFB" stroke-width="1"/>
                                        <circle id="hover-color" cx="70" cy="70" r="69.5" stroke="none" fill=""/>
                                    </g>
                                    <path id="Polygon_1" data-name="Polygon 1" d="M15,0,30,20H0Z"
                                          transform="translate(83 55) rotate(90)" fill="#fffdfb"/>

                                </svg>
                            </div>
                        </div>
                        : ''
                }


            </div>

        </StyledProject>
    );
};
const StyledProject = styled.section`
  .video__box__inner__click {
    //height: 80px;
    //width: 80px;
    //border-radius: 50%;
    //background-color: #8F6D4F;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .svg-container {
      display: inline-block;
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      svg{
        width: 100%;
        height: 100%;
      }
    }

    #hover-color {
      fill: transparent;
      transition: fill 0.8s;
    }
    #circle-border{
      transition: all 1s;
    }


    .svg-container:hover {

      #hover-color {
        fill: #AC8B7C;
        animation: fillAnimation 0.8s forwards;
      }
      #circle-border{
        stroke-width: 0;
      }
    }


    .svg-container:not(:hover) #hover-color {
      fill: transparent;
      animation: reverseFillAnimation 0.8s backwards;
    }

    @keyframes fillAnimation {
      0% {
        r: 0px;
      }
      100% {
        r: 70px;
      }
    }
    @keyframes reverseFillAnimation {
      0% {
        r: 69.5px;
      }
      100% {
        r: 0px;
      }
    }

  }

  .project {
    &__img {
      position: relative;
      padding-top: calc(550 / 570 * 100%);
      //overflow: hidden;
      z-index: 4;
      cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }


      &__content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        //transform: translateY(calc(100% - 30px));
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };
        @media(max-width: 767px){
          left: 20px;
          right: 20px;
        }

        
        //
        //h3{
        //  font-size: 24px;
        //  line-height: 32px;
        //  color: #FFFEFB;
        //  font-family: Kudryashev Display;
        //}

        p {
          font-size: 16px;
          font-family: 'Kudryashev Display';
          color: #F9F8F5;
          line-height: 24px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          margin-bottom: 7px;
          //opacity: 0.6;
          //transition: 0.5s;
        }
        .date{
          display: flex;
          justify-content: space-between;
          
          p{
            color: #F9F8F5;
          }
        }

        // &__bottom {
        //   opacity: 1;
        // }
      }


    }
  }



  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
    .video__box__inner__click{
      height: 50px;
      width: 50px;
      svg{
        height: 24px;
        width: 12px;
      }
    }
  }

`

export default Project;
