import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
// import {black, hover, transition} from "../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {Img} from "./Img";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import {Link, useLocation} from 'react-router-dom';
// import {useRouter} from "next/router";
// import arrowRight from '../public/images/static/arrow-white-right.svg';
import disableScroll from 'disable-scroll';
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchAbout} from "../api/redux/about";
import {fetchGlobal} from "../api/redux/menu";
// import a from '../../public/images/menu/8.jpg'
const MyComponent = () => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL
        dispath(fetchGlobal([api_url]))
    }, [])

    let getPost = useSelector(state => state.social)

    let social_link=getPost?.data?.[0]?.links;





    const location = useLocation();

    // gsap.registerPlugin(CSSPlugin);
    // const router = useRouter()
    let tl = new TimelineLite();
    let tl2 = new TimelineLite();
    let tll = new TimelineLite();
    let [videoId, setVideo] = useState("");
    let [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('')
    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    // mute un-mute toggle
    useEffect(() => {
        // document.querySelector('.mute-toggle').addEventListener('click', function () {
        //     this.classList.toggle('sound')
        //     if (this.classList.contains('sound')) {
        //         document.querySelector('.banner__video video').muted = false;
        //     } else {
        //         document.querySelector('.banner__video video').muted = true;
        //     }
        // })
        //
        // document.querySelector('.mute-toggle-mobile').addEventListener('click', function () {
        //     this.classList.toggle('sound')
        //     if (this.classList.contains('sound')) {
        //         document.querySelector('.banner__video video').muted = false;
        //     } else {
        //         document.querySelector('.banner__video video').muted = true;
        //     }
        // })

    }, [])


    //desktop menu contact dropdown
    useEffect(() => {
        document.querySelector(`.click-dropdown`)?.addEventListener('click', () => {
            if (!document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
                document.querySelector('.main-menu__contact').classList.add('open-dropdown');
                tl.to('.dropdown-list', {
                    duration: .2,
                    display: 'block',
                }).to('.dropdown-list', {
                    y: '20px',
                    duration: .4,
                    height: 'calc(100% - 20px)',
                    alpha: 1,
                    paddingTop: '33px',
                    paddingBottom: '15px',
                    ease: "circ.out"
                })
            } else {
                document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
                tl.to('.dropdown-list', {
                    duration: .3,
                    height: '0%',
                    alpha: 0,
                    paddingTop: '0',
                    y: '0px',
                    ease: "circ.out"
                }).to('.dropdown-list', {
                    duration: .2,
                    display: 'none',
                })
            }
        })
        window.addEventListener('click', (e) => {
            if (document.querySelector('.main-menu__contact') && document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
                if (!e.target.matches('.click-dropdown, .click-dropdown p, .click-dropdown svg, .click-dropdown line')) {
                    tl.to('.dropdown-list', {
                        duration: .3,
                        height: '0%',
                        alpha: 0,
                        paddingTop: '0',
                        y: '0px',
                        ease: "circ.out"
                    }).to('.dropdown-list', {
                        duration: .2,
                        display: 'none',
                    })

                    document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
                }
            }
        })
    }, []);

    //desktop menu on click toggle
    useEffect(() => {
        document.querySelector('.main-menu__menu__inner__open').addEventListener('click', () => {
            disableScroll.on()
            if (window.safari !== undefined) {
                document.body.classList.add('overflow')
            }
            document.querySelector('.main-menu').classList.add('menu-is-open')
            tll.to('.main-menu__items', {
                duration: '0',
                display: 'block'
            }).to('.main-menu__items', {
                opacity: '1',
                duration: '.6'
            }).to('.main-menu__items__img', {
                opacity: '1',
                duration: '.3'
            }, '-=.5').to('.main-menu__items__list', {
                duration: .5,
                y: 0,
                alpha: 1
            }, '-=.5').to('.shadows', {
                duration: .5,
                x: 0,
            }, '-=.5')
        })

        // desktop menu close
        const closeDelstopMenu = () => {
            tll.to('.main-menu__items__list', {
                duration: .2,
                y: '-40px',
                alpha: 0
            }).to('.main-menu__items', {
                opacity: '0',
                duration: '.3'
            }).to('.main-menu__items__img', {
                opacity: '0',
                duration: '.2'
            }, '-=.5').to('.main-menu__items__list', {
                duration: .2,
                y: '40px',
                alpha: 0
            }, '-=.3').to('.shadows', {
                duration: .2,
                x: '100%',
            }, '-=.5').to('.main-menu__items', {
                duration: '.4',
                opacity: 0
            }).to('.main-menu__items', {
                display: 'none'
            })
        }
        document.querySelector('.menu-close').addEventListener('click', () => {
            document.querySelector('.main-menu').classList.remove('menu-is-open')
            closeDelstopMenu()
            disableScroll.off()
            if (document.body.classList.contains('overflow')) {
                document.body.classList.remove('overflow')
            }
        })
        const getAllDesktopA = document.querySelectorAll('.main-menu__items__list a');
        getAllDesktopA.forEach(e => {
            e.addEventListener('click', () => {
                document.querySelector('.main-menu').classList.remove('menu-is-open')
                closeDelstopMenu()
                disableScroll.off()
                if (document.body.classList.contains('overflow')) {
                    document.body.classList.remove('overflow')
                }
            })
        })
    }, [])

    //desktop menu hover action
    useEffect(() => {
        let getLis = document.querySelectorAll('.menu-col li a'),
            getImgs = document.querySelectorAll('.main-menu__items__img li');
        for (var i = 0; i < getLis.length; i++) {
            getLis[i].setAttribute('data-index', i);

            getLis[i].onmouseover = function () {
                document.querySelector('.main-menu__items__img').classList.remove('parent-add')
                getImgs.forEach(e => {
                    e.classList.remove('active')
                })
                getImgs[this.getAttribute('data-index')].classList.add('active')
                setTimeout(() => {
                    document.querySelector('.main-menu__items__img').classList.add('parent-add')
                }, 200)
            };
        }
    }, [])


    //----- mobile menu action
    useEffect(() => {
        let getMobileMenuBar = document.querySelector('.main-menu-mobile');
        let getItemsParent = document.querySelector('.main-menu-mobile__items');
        let getItems = document.querySelectorAll('.main-item');
        let getBacks = document.querySelectorAll('.sub-menu-back');
        let getHamburgerClick = document.querySelector('#open-click')
        let getHamburgerCloseClick = document.querySelector('#close-click')

        // menu open toggle
        getHamburgerClick.addEventListener('click', () => {
            document.body.classList.add('menu-on')
            document.querySelector('.main-menu-mobile').classList.add('menu-is-open')
            getMobileMenuBar.classList.add('menu-open')
            document.body.classList.add('stop-scroll')
            tl2.to(getItemsParent, {
                duration: .2,
                display: 'block',
            }).to(getItemsParent, {
                duration: .2,
                x: 0
            }, '-=.2')
        })

        getHamburgerCloseClick?.addEventListener('click', () => {
            getMobileMenuBar.classList.remove('menu-open')
            document.body.classList.remove('menu-on')
            document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
            document.body.classList.remove('stop-scroll')
            if (document.querySelector('.main-item.active')) {
                getItemsParent.classList.remove('active')
                document.querySelector('.main-item.active').classList.remove('active')
            }

            tl2.to(getItemsParent, {
                duration: .2,
                x: '100%'
            }).to(getItemsParent, {
                duration: .2,
                display: 'none'
            })
        });


        // sub menu toggle
        getItems.forEach(i => {
            i.addEventListener('click', () => {
                getItemsParent.classList.add('active')
                i.classList.add('active')
            })
        })

        getBacks.forEach(i => {
            i.addEventListener('click', (e) => {

                getItemsParent.classList.remove('active')
                i.parentNode.parentElement.classList.remove('active')
                e.stopPropagation()
            })
        })

        // on click a tag menu hide
        let getAlla = document.querySelectorAll('.main-menu-mobile a');
        getAlla.forEach(i => {
            i.addEventListener('click', (e) => {
                // e.stopPropagation();
                getMobileMenuBar.classList.remove('menu-open');
                document.body.classList.remove('menu-on')
                document.querySelector('.main-menu-mobile').classList.remove('menu-is-open')
                document.body.classList.remove('stop-scroll');
                // console.log('have or not', document.querySelector('.main-item.active'))
                setTimeout(() => {
                    if (document.querySelector('.main-item.active')) {

                        getItemsParent.classList.remove('active')
                        document.querySelector('.main-item.active').classList.remove('active')
                    }
                }, 300)

                tl2.to(getItemsParent, {
                    duration: .3,
                    x: '100%'
                }).to(getItemsParent, {
                    duration: .3,
                    display: 'none'
                })
            })
        })
    }, [])


    // menu fixed on scroll
    // useEffect(() => {
    //     if (document.body.classList.contains("scroll-down")) {
    //         document.body.classList.remove("scroll-down");
    //     }
    // });


    useEffect(() => {
        const whichPage = location.pathname

    }, [location?.pathname])


    // const [scrollUp, setScrollUp] = useState(false)
    // const [scrollDown, setScrollDown] = useState(false)


    // useEffect(() => {
    //
    //     const body = document.body;
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     let lastScroll = 0;
    //     let howMuchScroll = 20;
    //
    //     window.addEventListener("scroll", () => {
    //         let currentScroll = window.pageYOffset;
    //
    //         if (currentScroll <= howMuchScroll) {
    //             setScrollUp(false)
    //             setScrollDown(false)
    //
    //             return;
    //         }
    //         if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
    //             setScrollDown(true)
    //             setScrollUp(false)
    //
    //         } else if (
    //             currentScroll < lastScroll
    //         ) {
    //
    //             setScrollUp(true)
    //             setScrollDown(false)
    //
    //         }
    //         lastScroll = currentScroll;
    //     });
    //
    //
    // }, []);



    const mobileNumber = getPost?.data?.[0]?.contact?.phone;
    // Replace with your desired mobile number

    const handleCallClick = () => {
        window.location.href = `tel:${mobileNumber}`;
    };



    return (
        <>
            {/* desktop menu*/}
            <StyledMenu
                className={`main-menu menu-bar`}>
                {/*menu bar*/}
                <Container>
                    <Row>

                        <Col className={'main-menu__logo justify-content-start d-flex'}>

                            <Link to={'/'}><a>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"
                                         viewBox="0 0 50.531 50.548">
                                        <g id="Group_19837" data-name="Group 19837"
                                           transform="translate(-1135.608 -86.953)">
                                            <g id="Group_19837-2" data-name="Group 19837"
                                               transform="translate(1135.608 86.953)">
                                                <path id="Path_9494" data-name="Path 9494"
                                                      d="M1135.642,137.5l-.034-50.515,50.5-.033.033,50.515Zm1.269-49.214.032,47.91,47.892-.031-.031-47.91Z"
                                                      transform="translate(-1135.608 -86.953)" fill="#fff"/>
                                            </g>
                                            <g id="Group_19839" data-name="Group 19839"
                                               transform="translate(1140.251 96.955)">
                                                <path id="Path_9495" data-name="Path 9495"
                                                      d="M1175.4,198.729V172.17l5.512,3.6V188.02l4.838-4.874h6.338l-4.95,5.025,4.892,10.559h-6.152l-3.091-5.946-1.837,1.575v4.371Z"
                                                      transform="translate(-1175.271 -171.949)" fill="#fff"/>
                                                <path id="Path_9496" data-name="Path 9496"
                                                      d="M1328.712,170.755v26.7h5.663v-9.369a1.842,1.842,0,0,1,1.987-1.725c1.688,0,1.8,1.8,1.8,1.8v9.293h5.738v-9.293c0-5.887-4.688-8.513-9.527-4.987v-8.831Z"
                                                      transform="translate(-1310.19 -170.704)" fill="#fff"/>
                                                <path id="Path_9497" data-name="Path 9497"
                                                      d="M1469.561,197.113V170.329l5.664,3.577v23.208Z"
                                                      transform="translate(-1434.143 -170.329)" fill="#fff"/>
                                                <g id="Group_19838" data-name="Group 19838"
                                                   transform="translate(0 29.127)">
                                                    <path id="Path_9498" data-name="Path 9498"
                                                          d="M1175.005,417.847v.542h.542v.313h-.542v1.219c0,.28.073.439.284.439a.648.648,0,0,0,.219-.028l.017.313a.958.958,0,0,1-.335.056.518.518,0,0,1-.408-.173.939.939,0,0,1-.146-.593V418.7h-.322v-.313h.322v-.42Z"
                                                          transform="translate(-1174.313 -417.282)" fill="#fff"/>
                                                    <path id="Path_9499" data-name="Path 9499"
                                                          d="M1188.14,413.563h.378v1.415h.009a.746.746,0,0,1,.271-.294.728.728,0,0,1,.387-.112c.28,0,.726.187.726.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.557.557,0,0,0-.507.406.564.564,0,0,0-.026.2v1.364h-.378Z"
                                                          transform="translate(-1186.481 -413.512)" fill="#fff"/>
                                                    <path id="Path_9500" data-name="Path 9500"
                                                          d="M1207.13,423.227a.693.693,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                                          transform="translate(-1202.876 -420.915)" fill="#fff"/>
                                                    <path id="Path_9501" data-name="Path 9501"
                                                          d="M1232.473,423.867a1.007,1.007,0,0,0,.5.159c.271,0,.4-.145.4-.336s-.108-.3-.383-.411c-.378-.149-.554-.369-.554-.64a.666.666,0,0,1,.718-.663.905.905,0,0,1,.507.14l-.09.3a.761.761,0,0,0-.425-.135.309.309,0,0,0-.344.308c0,.187.12.271.391.388.357.145.546.341.546.677a.684.684,0,0,1-.774.682,1.112,1.112,0,0,1-.584-.159Z"
                                                          transform="translate(-1225.417 -420.915)" fill="#fff"/>
                                                    <path id="Path_9502" data-name="Path 9502"
                                                          d="M1247.826,415.183a.236.236,0,0,1-.241.252.233.233,0,0,1-.228-.252.241.241,0,0,1,.236-.257A.238.238,0,0,1,1247.826,415.183Zm-.421,2.9v-2.261h.378v2.261Z"
                                                          transform="translate(-1238.595 -414.711)" fill="#fff"/>
                                                    <path id="Path_9503" data-name="Path 9503"
                                                          d="M1256.81,423.951a1.4,1.4,0,0,1-.3,1.037,1.084,1.084,0,0,1-.761.271,1.258,1.258,0,0,1-.692-.187l.094-.318a1.067,1.067,0,0,0,.61.182c.387,0,.67-.22.67-.794v-.248h-.009a.726.726,0,0,1-.662.378.984.984,0,0,1-.885-1.1c0-.766.46-1.2.941-1.2a.687.687,0,0,1,.649.388h.009l.013-.341h.335c-.013.159-.017.346-.017.617Zm-.378-1.037a.586.586,0,0,0-.021-.182.55.55,0,0,0-.529-.439c-.361,0-.619.332-.619.855,0,.444.211.813.615.813a.552.552,0,0,0,.524-.42.773.773,0,0,0,.03-.22Z"
                                                          transform="translate(-1245.215 -420.915)" fill="#fff"/>
                                                    <path id="Path_9504" data-name="Path 9504"
                                                          d="M1275.854,422.634c0-.238,0-.425-.017-.612h.335l.021.374h.009a.763.763,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.637.637,0,0,0-.026.191v1.359h-.378Z"
                                                          transform="translate(-1263.658 -420.915)" fill="#fff"/>
                                                    <path id="Path_9505" data-name="Path 9505"
                                                          d="M1304.235,423.133a1.068,1.068,0,0,1-1.036,1.2,1.042,1.042,0,0,1-1-1.163,1.068,1.068,0,0,1,1.032-1.2A1.034,1.034,0,0,1,1304.235,423.133Zm-1.646.023c0,.5.258.869.627.869s.632-.369.632-.878c0-.383-.176-.864-.623-.864S1302.589,422.732,1302.589,423.157Z"
                                                          transform="translate(-1286.86 -420.915)" fill="#fff"/>
                                                    <path id="Path_9506" data-name="Path 9506"
                                                          d="M1321.334,416.5v-1.948h-.288v-.313h.288v-.107a1.146,1.146,0,0,1,.241-.789.692.692,0,0,1,.507-.21.8.8,0,0,1,.322.065l-.052.318a.514.514,0,0,0-.241-.051c-.322,0-.4.3-.4.654v.121h.5v.313h-.5V416.5Z"
                                                          transform="translate(-1303.444 -413.135)" fill="#fff"/>
                                                    <path id="Path_9507" data-name="Path 9507"
                                                          d="M1340.285,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.578,1.578,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.067,1.067,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                                          transform="translate(-1320.057 -420.915)" fill="#fff"/>
                                                    <path id="Path_9508" data-name="Path 9508"
                                                          d="M1357.183,422.363l.3.486c.082.126.146.238.215.364h.009c.069-.131.137-.248.21-.369l.288-.481h.412l-.709,1.093.726,1.168h-.426l-.309-.509c-.082-.131-.15-.257-.223-.393h-.009c-.069.135-.137.257-.219.393l-.3.509h-.417l.739-1.154-.7-1.107Z"
                                                          transform="translate(-1334.844 -421.256)" fill="#fff"/>
                                                    <path id="Path_9509" data-name="Path 9509"
                                                          d="M1375.483,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.761.761,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                                          transform="translate(-1349.913 -420.915)" fill="#fff"/>
                                                    <path id="Path_9510" data-name="Path 9510"
                                                          d="M1390.081,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.077,1.077,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                                          transform="translate(-1363.88 -420.915)" fill="#fff"/>
                                                    <path id="Path_9511" data-name="Path 9511"
                                                          d="M1408.921,413.563h.378v3.317h-.378Z"
                                                          transform="translate(-1380.777 -413.512)" fill="#fff"/>
                                                    <path id="Path_9512" data-name="Path 9512"
                                                          d="M1417.375,413.563h.378v3.317h-.378Z"
                                                          transform="translate(-1388.217 -413.512)" fill="#fff"/>
                                                    <path id="Path_9513" data-name="Path 9513"
                                                          d="M1424.936,423.227a.693.693,0,0,0,.713.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                                          transform="translate(-1394.553 -420.915)" fill="#fff"/>
                                                    <path id="Path_9514" data-name="Path 9514"
                                                          d="M1443.651,422.634c0-.238,0-.425-.017-.612h.335l.021.374H1444a.762.762,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.633.633,0,0,0-.026.191v1.359h-.378Z"
                                                          transform="translate(-1411.326 -420.915)" fill="#fff"/>
                                                    <path id="Path_9515" data-name="Path 9515"
                                                          d="M1464.039,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.76.76,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                                          transform="translate(-1427.846 -420.915)" fill="#fff"/>
                                                    <path id="Path_9516" data-name="Path 9516"
                                                          d="M1478.6,423.227a.694.694,0,0,0,.714.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                                          transform="translate(-1441.78 -420.915)" fill="#fff"/>
                                                    <path id="Path_9517" data-name="Path 9517"
                                                          d="M1496.3,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.512,0Z"
                                                          transform="translate(-1457.67 -433.901)" fill="#fff"/>
                                                    <path id="Path_9518" data-name="Path 9518"
                                                          d="M1503.71,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                                          transform="translate(-1464.195 -433.901)" fill="#fff"/>
                                                    <path id="Path_9519" data-name="Path 9519"
                                                          d="M1511.125,437.026a.269.269,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                                          transform="translate(-1470.721 -433.901)" fill="#fff"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                            </a></Link>
                        </Col>

                        <Col className={'main-menu__menu'}>


                            <div className="main-menu__menu__inner">
                                <a href={`tel:${mobileNumber}`}>

                                    <p style={{ color: 'white', cursor: 'pointer' }} >
                                        {mobileNumber}
                                    </p>
                                </a>
                                <div className="wrap main-menu__menu__inner__open ">
                                    <div className="line"/>
                                    <div className="line"/>
                                    <div className="line"/>
                                    {/*<div className="show"/>*/}
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>

                {/*menu items*/}
                <div className="main-menu__items">
                    <div className="d-flex">
                        {/*menu left img*/}
                        <Col sm={5} className={'p-0'}>
                            <div className="main-menu__items__img">
                                <li className={'active'}><Img src={'./images/menu/image04.jpg'}/></li>
                                <li><Img src={'./images/menu/image.jpg'}/></li>
                                <li><Img src={'./images/menu/image02.jpg'}/></li>
                                <li><Img src={'./images/menu/image03.jpg'}/></li>
                                <li><Img src={'./images/menu/image04.jpg'}/></li>
                                <li><Img src={'./images/menu/image05.jpg'}/></li>
                                <li><Img src={'./images/menu/image06.jpg'}/></li>
                                {/*<li><Img src={'./images/menu/image07.jpg'}/></li>*/}
                                <li><Img src={'./images/menu/8.jpg'}/></li>
                                <li><Img src={'./images/menu/9.jpg'}/></li>
                                <li><Img src={'./images/menu/10.jpg'}/></li>

                            </div>
                        </Col>

                        {/*menu items right*/}
                        <Col sm={7} className={'p-0'}>
                            <div className="main-menu__items__list d-flex">
                                <div className="menu-close hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                         viewBox="0 0 12.828 12.828">
                                        <g id="Group_17856" data-name="Group 17856"
                                           transform="translate(12811.914 23.914)">
                                            <line id="Line_12363" data-name="Line 12363" x2="10" y2="10"
                                                  transform="translate(-12810.5 -22.5)" fill="none" stroke="#f7f3f0"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_12364" data-name="Line 12364" x1="10" y2="10"
                                                  transform="translate(-12810.5 -22.5)" fill="none" stroke="#f7f3f0"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>


                                </div>
                                <Col className={'menu-col'} sm={{span: 5, offset: 1}}>
                                    <ul>
                                        <li className={location.pathname === '/' ? 'active' : ''}><Link
                                            to="/">Home</Link></li>
                                        <li className={location.pathname === '/about' ? 'active' : ''}><Link
                                            to="/about">About</Link></li>
                                        <li className={location.pathname === '/projects' ? 'active' : ''}><Link
                                            to="/project">Projects</Link></li>
                                        <li className={location.pathname === '/work-process' ? 'active' : ''}><Link
                                            to="/media">Media</Link></li>
                                        <li className={location.pathname === '/career' ? 'active' : ''}><Link
                                            to="/career">Career</Link></li>
                                        <li className={location.pathname === '/contact' ? 'active' : ''}><Link
                                            to="/contact">Contact</Link></li>

                                    </ul>
                                </Col>
                                <Col className={'menu-col'} sm={5}>
                                    <div className="leaf-list__wrap">
                                        <ul>
                                            <li className={`${location.pathname === '/project' && location.search === '?status=completed' ? 'active' : ''}`}>
                                                <Link to="/project?status=completed">Completed</Link>
                                            </li>
                                            <li className={`${location.pathname === '/project' && location.search === '?status=ongoing' ? 'active' : ''}`}>
                                                <Link to="/project?status=ongoing">Ongoing</Link>
                                            </li>
                                            <li className={`${location.pathname === '/project' && location.search === '?status=upcoming' ? 'active' : ''}`}>
                                                <Link to="/project?status=upcoming">Upcoming</Link>
                                            </li>


                                        </ul>
                                        <div className="right-leaf">
                                            <img src="/images/static/leaf.svg" alt=""/>
                                        </div>
                                    </div>
                                </Col>

                                <div className="main-menu__items__list__bottom">
                                    <div className="copyright">
                                        <div className="social-icon">
                                            <ul>
                                                <li>
                                                    <a href={social_link?.facebook} target={'_blank'}>
                                                        <div className=" socila-icon__single hovercenter">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.255" height="13.424" viewBox="0 0 6.255 13.424">
                                                                <path id="Path_26" data-name="Path 26" d="M1013.278,3919.394v-1.157a2.52,2.52,0,0,1,.025-.4.785.785,0,0,1,.11-.281.45.45,0,0,1,.277-.191,2.009,2.009,0,0,1,.51-.053h1.156v-2.313h-1.849a3.007,3.007,0,0,0-2.305.762,3.206,3.206,0,0,0-.7,2.244v1.393h-1.385v2.313h1.384v6.712h2.778v-6.712h1.849l.244-2.313Z" transform="translate(-1009.116 -3914.995)" fill="#2d2d2d"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </li>



                                                <li>
                                                    <a href={social_link?.instagram} target={'_blank'}>
                                                        <div className=" socila-icon__single hovercenter">
                                                            <svg id="Group_13724" data-name="Group 13724" xmlns="http://www.w3.org/2000/svg" width="14.346" height="14.346" viewBox="0 0 14.346 14.346">
                                                                <path id="Path_5912" data-name="Path 5912" d="M7.173,1.293c1.915,0,2.142.007,2.9.042a3.967,3.967,0,0,1,1.332.247,2.222,2.222,0,0,1,.825.537,2.222,2.222,0,0,1,.537.825,3.972,3.972,0,0,1,.247,1.332c.034.756.042.983.042,2.9s-.007,2.142-.042,2.9a3.972,3.972,0,0,1-.247,1.332A2.375,2.375,0,0,1,11.4,12.765a3.967,3.967,0,0,1-1.332.247c-.756.034-.983.042-2.9.042s-2.142-.007-2.9-.042a3.967,3.967,0,0,1-1.332-.247,2.222,2.222,0,0,1-.825-.537,2.222,2.222,0,0,1-.537-.825,3.967,3.967,0,0,1-.247-1.332c-.034-.756-.042-.983-.042-2.9s.007-2.142.042-2.9a3.967,3.967,0,0,1,.247-1.332,2.222,2.222,0,0,1,.537-.825,2.222,2.222,0,0,1,.825-.537,3.967,3.967,0,0,1,1.332-.247c.756-.034.983-.042,2.9-.042M7.173,0C5.225,0,4.981.008,4.216.043A5.266,5.266,0,0,0,2.475.377,3.516,3.516,0,0,0,1.2,1.2,3.516,3.516,0,0,0,.377,2.475,5.266,5.266,0,0,0,.043,4.216C.008,4.981,0,5.225,0,7.173s.008,2.192.043,2.958a5.266,5.266,0,0,0,.333,1.741A3.516,3.516,0,0,0,1.2,13.143a3.518,3.518,0,0,0,1.271.827,5.266,5.266,0,0,0,1.741.333c.765.035,1.009.043,2.958.043s2.192-.008,2.958-.043a5.266,5.266,0,0,0,1.741-.333,3.668,3.668,0,0,0,2.1-2.1,5.266,5.266,0,0,0,.333-1.741c.035-.765.043-1.009.043-2.958s-.008-2.192-.043-2.958a5.266,5.266,0,0,0-.333-1.741A3.518,3.518,0,0,0,13.143,1.2,3.516,3.516,0,0,0,11.872.377,5.266,5.266,0,0,0,10.131.043C9.366.008,9.121,0,7.173,0" fill="#2d2d2d"/>
                                                                <path id="Path_5913" data-name="Path 5913" d="M24.359,20.676a3.684,3.684,0,1,0,3.683,3.683,3.684,3.684,0,0,0-3.683-3.683m0,6.075a2.391,2.391,0,1,1,2.391-2.391,2.391,2.391,0,0,1-2.391,2.391" transform="translate(-17.186 -17.186)" fill="#2d2d2d"/>
                                                                <path id="Path_5914" data-name="Path 5914" d="M61.809,15.574a.861.861,0,1,1-.861-.861.861.861,0,0,1,.861.861" transform="translate(-49.945 -12.229)" fill="#2d2d2d"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href={social_link?.linkedin} target={'_blank'}>
                                                        <div className=" socila-icon__single hovercenter">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.623" height="10.98" viewBox="0 0 11.623 10.98">
                                                                <g id="Group_28" data-name="Group 28" transform="translate(0 0)">
                                                                    <path id="LinkedIn" d="M1099.058,3923.038v4.248h-2.491v-3.963c0-1-.36-1.675-1.263-1.675a1.361,1.361,0,0,0-1.278.9,1.664,1.664,0,0,0-.083.6v4.137h-2.492s.033-6.713,0-7.409h2.492v1.051l-.016.023h.016v-.023a2.478,2.478,0,0,1,2.246-1.225C1097.829,3919.7,1099.058,3920.762,1099.058,3923.038Zm-10.214-6.732a1.285,1.285,0,1,0-.033,2.561h.017a1.286,1.286,0,1,0,.016-2.561Zm-1.262,10.98h2.491v-7.409h-2.491Z" transform="translate(-1087.435 -3916.306)" fill="#2d2d2d"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={social_link?.youtube} target={'_blank'}>
                                                        <div className=" socila-icon__single hovercenter">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.787" height="8.951" viewBox="0 0 12.787 8.951">
                                                                <g id="Group_31" data-name="Group 31" transform="translate(0 0)">
                                                                    <path id="Path_29" data-name="Path 29" d="M1178.776,3919.591c-.462-.545-1.314-.768-2.942-.768h-5.908c-1.665,0-2.531.237-2.992.818a4.155,4.155,0,0,0-.448,2.556v2.2c0,2.238.532,3.375,3.44,3.375h5.908a3.737,3.737,0,0,0,2.7-.678,3.539,3.539,0,0,0,.741-2.7v-2.2A4.052,4.052,0,0,0,1178.776,3919.591Zm-4.08,4.012-2.683,1.395a.412.412,0,0,1-.6-.364v-2.78a.411.411,0,0,1,.2-.351.417.417,0,0,1,.4-.014l2.683,1.386a.41.41,0,0,1,0,.729Z" transform="translate(-1166.487 -3918.823)" fill="#2d2d2d"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <p>© 2023 Kopotakkho Homes Limited. All Rights Reserved..</p>
                                        <p><a href="https://dcastalia.com" target={'_blank'}>Designed & Developed by
                                            Dcastalia</a></p>

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                </div>
            </StyledMenu>

            {/*mobile menu*/}
            <StyledMobileMenu
                className={`main-menu-mobile`}>


                <div className="main-menu-mobile__bar">

                    <div className="main-menu-mobile__bar__logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50.531" height="50.548"
                             viewBox="0 0 50.531 50.548">
                            <g id="Group_19837" data-name="Group 19837"
                               transform="translate(-1135.608 -86.953)">
                                <g id="Group_19837-2" data-name="Group 19837"
                                   transform="translate(1135.608 86.953)">
                                    <path id="Path_9494" data-name="Path 9494"
                                          d="M1135.642,137.5l-.034-50.515,50.5-.033.033,50.515Zm1.269-49.214.032,47.91,47.892-.031-.031-47.91Z"
                                          transform="translate(-1135.608 -86.953)" fill="#fff"/>
                                </g>
                                <g id="Group_19839" data-name="Group 19839"
                                   transform="translate(1140.251 96.955)">
                                    <path id="Path_9495" data-name="Path 9495"
                                          d="M1175.4,198.729V172.17l5.512,3.6V188.02l4.838-4.874h6.338l-4.95,5.025,4.892,10.559h-6.152l-3.091-5.946-1.837,1.575v4.371Z"
                                          transform="translate(-1175.271 -171.949)" fill="#fff"/>
                                    <path id="Path_9496" data-name="Path 9496"
                                          d="M1328.712,170.755v26.7h5.663v-9.369a1.842,1.842,0,0,1,1.987-1.725c1.688,0,1.8,1.8,1.8,1.8v9.293h5.738v-9.293c0-5.887-4.688-8.513-9.527-4.987v-8.831Z"
                                          transform="translate(-1310.19 -170.704)" fill="#fff"/>
                                    <path id="Path_9497" data-name="Path 9497"
                                          d="M1469.561,197.113V170.329l5.664,3.577v23.208Z"
                                          transform="translate(-1434.143 -170.329)" fill="#fff"/>
                                    <g id="Group_19838" data-name="Group 19838"
                                       transform="translate(0 29.127)">
                                        <path id="Path_9498" data-name="Path 9498"
                                              d="M1175.005,417.847v.542h.542v.313h-.542v1.219c0,.28.073.439.284.439a.648.648,0,0,0,.219-.028l.017.313a.958.958,0,0,1-.335.056.518.518,0,0,1-.408-.173.939.939,0,0,1-.146-.593V418.7h-.322v-.313h.322v-.42Z"
                                              transform="translate(-1174.313 -417.282)" fill="#fff"/>
                                        <path id="Path_9499" data-name="Path 9499"
                                              d="M1188.14,413.563h.378v1.415h.009a.746.746,0,0,1,.271-.294.728.728,0,0,1,.387-.112c.28,0,.726.187.726.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.557.557,0,0,0-.507.406.564.564,0,0,0-.026.2v1.364h-.378Z"
                                              transform="translate(-1186.481 -413.512)" fill="#fff"/>
                                        <path id="Path_9500" data-name="Path 9500"
                                              d="M1207.13,423.227a.693.693,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                              transform="translate(-1202.876 -420.915)" fill="#fff"/>
                                        <path id="Path_9501" data-name="Path 9501"
                                              d="M1232.473,423.867a1.007,1.007,0,0,0,.5.159c.271,0,.4-.145.4-.336s-.108-.3-.383-.411c-.378-.149-.554-.369-.554-.64a.666.666,0,0,1,.718-.663.905.905,0,0,1,.507.14l-.09.3a.761.761,0,0,0-.425-.135.309.309,0,0,0-.344.308c0,.187.12.271.391.388.357.145.546.341.546.677a.684.684,0,0,1-.774.682,1.112,1.112,0,0,1-.584-.159Z"
                                              transform="translate(-1225.417 -420.915)" fill="#fff"/>
                                        <path id="Path_9502" data-name="Path 9502"
                                              d="M1247.826,415.183a.236.236,0,0,1-.241.252.233.233,0,0,1-.228-.252.241.241,0,0,1,.236-.257A.238.238,0,0,1,1247.826,415.183Zm-.421,2.9v-2.261h.378v2.261Z"
                                              transform="translate(-1238.595 -414.711)" fill="#fff"/>
                                        <path id="Path_9503" data-name="Path 9503"
                                              d="M1256.81,423.951a1.4,1.4,0,0,1-.3,1.037,1.084,1.084,0,0,1-.761.271,1.258,1.258,0,0,1-.692-.187l.094-.318a1.067,1.067,0,0,0,.61.182c.387,0,.67-.22.67-.794v-.248h-.009a.726.726,0,0,1-.662.378.984.984,0,0,1-.885-1.1c0-.766.46-1.2.941-1.2a.687.687,0,0,1,.649.388h.009l.013-.341h.335c-.013.159-.017.346-.017.617Zm-.378-1.037a.586.586,0,0,0-.021-.182.55.55,0,0,0-.529-.439c-.361,0-.619.332-.619.855,0,.444.211.813.615.813a.552.552,0,0,0,.524-.42.773.773,0,0,0,.03-.22Z"
                                              transform="translate(-1245.215 -420.915)" fill="#fff"/>
                                        <path id="Path_9504" data-name="Path 9504"
                                              d="M1275.854,422.634c0-.238,0-.425-.017-.612h.335l.021.374h.009a.763.763,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.637.637,0,0,0-.026.191v1.359h-.378Z"
                                              transform="translate(-1263.658 -420.915)" fill="#fff"/>
                                        <path id="Path_9505" data-name="Path 9505"
                                              d="M1304.235,423.133a1.068,1.068,0,0,1-1.036,1.2,1.042,1.042,0,0,1-1-1.163,1.068,1.068,0,0,1,1.032-1.2A1.034,1.034,0,0,1,1304.235,423.133Zm-1.646.023c0,.5.258.869.627.869s.632-.369.632-.878c0-.383-.176-.864-.623-.864S1302.589,422.732,1302.589,423.157Z"
                                              transform="translate(-1286.86 -420.915)" fill="#fff"/>
                                        <path id="Path_9506" data-name="Path 9506"
                                              d="M1321.334,416.5v-1.948h-.288v-.313h.288v-.107a1.146,1.146,0,0,1,.241-.789.692.692,0,0,1,.507-.21.8.8,0,0,1,.322.065l-.052.318a.514.514,0,0,0-.241-.051c-.322,0-.4.3-.4.654v.121h.5v.313h-.5V416.5Z"
                                              transform="translate(-1303.444 -413.135)" fill="#fff"/>
                                        <path id="Path_9507" data-name="Path 9507"
                                              d="M1340.285,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.578,1.578,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.859.645.859,1.06a1.067,1.067,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                              transform="translate(-1320.057 -420.915)" fill="#fff"/>
                                        <path id="Path_9508" data-name="Path 9508"
                                              d="M1357.183,422.363l.3.486c.082.126.146.238.215.364h.009c.069-.131.137-.248.21-.369l.288-.481h.412l-.709,1.093.726,1.168h-.426l-.309-.509c-.082-.131-.15-.257-.223-.393h-.009c-.069.135-.137.257-.219.393l-.3.509h-.417l.739-1.154-.7-1.107Z"
                                              transform="translate(-1334.844 -421.256)" fill="#fff"/>
                                        <path id="Path_9509" data-name="Path 9509"
                                              d="M1375.483,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.761.761,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                              transform="translate(-1349.913 -420.915)" fill="#fff"/>
                                        <path id="Path_9510" data-name="Path 9510"
                                              d="M1390.081,423.227a.694.694,0,0,0,.713.785,1.261,1.261,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.077,1.077,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                              transform="translate(-1363.88 -420.915)" fill="#fff"/>
                                        <path id="Path_9511" data-name="Path 9511"
                                              d="M1408.921,413.563h.378v3.317h-.378Z"
                                              transform="translate(-1380.777 -413.512)" fill="#fff"/>
                                        <path id="Path_9512" data-name="Path 9512"
                                              d="M1417.375,413.563h.378v3.317h-.378Z"
                                              transform="translate(-1388.217 -413.512)" fill="#fff"/>
                                        <path id="Path_9513" data-name="Path 9513"
                                              d="M1424.936,423.227a.693.693,0,0,0,.713.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.576,1.576,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                              transform="translate(-1394.553 -420.915)" fill="#fff"/>
                                        <path id="Path_9514" data-name="Path 9514"
                                              d="M1443.651,422.634c0-.238,0-.425-.017-.612h.335l.021.374H1444a.762.762,0,0,1,.688-.42c.288,0,.735.187.735.962v1.345h-.378v-1.3c0-.364-.125-.668-.481-.668a.555.555,0,0,0-.507.42.633.633,0,0,0-.026.191v1.359h-.378Z"
                                              transform="translate(-1411.326 -420.915)" fill="#fff"/>
                                        <path id="Path_9515" data-name="Path 9515"
                                              d="M1464.039,424.2a1.362,1.362,0,0,1-.6.131,1.036,1.036,0,0,1-1.036-1.154,1.134,1.134,0,0,1,1.642-1.084l-.086.313a.867.867,0,0,0-.438-.107.76.76,0,0,0-.735.855.75.75,0,0,0,.722.846,1.012,1.012,0,0,0,.464-.107Z"
                                              transform="translate(-1427.846 -420.915)" fill="#fff"/>
                                        <path id="Path_9516" data-name="Path 9516"
                                              d="M1478.6,423.227a.694.694,0,0,0,.714.785,1.26,1.26,0,0,0,.576-.117l.069.294a1.577,1.577,0,0,1-.7.145,1.017,1.017,0,0,1-1.023-1.145c0-.682.37-1.214.976-1.214.683,0,.86.645.86,1.06a1.093,1.093,0,0,1-.013.192Zm1.109-.294c0-.257-.1-.663-.524-.663-.387,0-.55.378-.58.663Z"
                                              transform="translate(-1441.78 -420.915)" fill="#fff"/>
                                        <path id="Path_9517" data-name="Path 9517"
                                              d="M1496.3,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.512,0Z"
                                              transform="translate(-1457.67 -433.901)" fill="#fff"/>
                                        <path id="Path_9518" data-name="Path 9518"
                                              d="M1503.71,437.026a.27.27,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                              transform="translate(-1464.195 -433.901)" fill="#fff"/>
                                        <path id="Path_9519" data-name="Path 9519"
                                              d="M1511.125,437.026a.269.269,0,0,1,.258-.294.264.264,0,0,1,.254.294.258.258,0,1,1-.511,0Z"
                                              transform="translate(-1470.721 -433.901)" fill="#fff"/>
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>
                    <div className="main-menu-mobile__bar__hamburger d-flex justify-content-end">
                        <p>{mobileNumber}</p>
                        <svg id='open-click' xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22">
                            <g id="Hamburger" data-name="Group 19834" transform="translate(-1129.5 54.5)">
                                <line id="Line_12376" data-name="Line 12376" x2="30" transform="translate(1129.5 -33.5)" fill="none" stroke="#fffefb" stroke-width="2"/>
                                <line id="Line_12375" data-name="Line 12375" x2="30" transform="translate(1129.5 -43.5)" fill="none" stroke="#fffefb" stroke-width="2"/>
                                <line id="Line_12374" data-name="Line 12374" x2="30" transform="translate(1129.5 -53.5)" fill="none" stroke="#fffefb" stroke-width="2"/>
                            </g>
                        </svg>




                    </div>
                </div>

                <div className="main-menu-mobile__items">

                    <div className="main-menu-mobile__items__head">




                        {/*<svg id={'close-click'} xmlns="http://www.w3.org/2000/svg" width="31.113" height="31.113"*/}
                        {/*     viewBox="0 0 31.113 31.113">*/}
                        {/*    <line id="Line_62" data-name="Line 62" x1="40"*/}
                        {/*          transform="translate(1.414 29.698) rotate(-45)" fill="none" stroke="#f7f3f0"*/}
                        {/*          stroke-linecap="round" stroke-width="2"/>*/}
                        {/*    <line id="Line_63" data-name="Line 63" x1="40" transform="translate(1.414 1.414) rotate(45)"*/}
                        {/*          fill="none" stroke="#f7f3f0" stroke-linecap="round" stroke-width="2"/>*/}
                        {/*</svg>*/}
<div className="menu-close">
    <svg id={'close-click'} xmlns="http://www.w3.org/2000/svg" width="12" height="12"
         viewBox="0 0 12.828 12.828">
        <g id="Group_17856" data-name="Group 17856"
           transform="translate(12811.914 23.914)">
            <line id="Line_12363" data-name="Line 12363" x2="10" y2="10"
                  transform="translate(-12810.5 -22.5)" fill="none" stroke="#f7f3f0"
                  stroke-linecap="round" stroke-width="2"/>
            <line id="Line_12364" data-name="Line 12364" x1="10" y2="10"
                  transform="translate(-12810.5 -22.5)" fill="none" stroke="#f7f3f0"
                  stroke-linecap="round" stroke-width="2"/>
        </g>
    </svg>
</div>


                    </div>
                    <div className="main-menu-mobile__items__body">
                        <div className="main-menu-mobile__items__body__list-wrap">


                        <ul className={'menu-list'}>

                            <li className={location?.pathname === '/' ? 'active' : ''}><Link to="/">Home</Link></li>
                            <li className={location?.pathname === '/about' ? 'active' : ''}><Link to="/about">About</Link></li>
                            <li className={location?.pathname === '/project' ? 'active' : ''}><Link to="/project">Projects</Link></li>
                            <li className={location?.pathname === '/media' ? 'active' : ''}><Link to="/media">Media</Link></li>
                            <li className={location.pathname === '/career' ? 'active' : ''}><Link
                                to="/career">Career</Link></li>
                            <li className={location?.pathname === '/contact' ? 'active' : ''}><Link
                                to="/contact">Contact</Link></li>


                        </ul>

                        <div className="leaf-list__wrap">
                            <ul>
                                <li className={`${location?.pathname === '/project' && location?.search === '?status=completed' ? 'active' : ''}`}>
                                    <Link to="/project?status=completed">Completed Projects</Link>
                                </li>
                                <li className={`${location?.pathname === '/project' && location?.search === '?status=ongoing' ? 'active' : ''}`}>
                                    <Link to="/project?status=ongoing">Ongoing Projects</Link>
                                </li>
                                <li className={`${location?.pathname === '/project' && location?.search === '?status=upcoming' ? 'active' : ''}`}>
                                    <Link to="/project?status=upcoming">Upcoming Projects</Link>
                                </li>

                            </ul>

                            <div className="right-leaf">
                                <img src="/images/static/leaf.svg" alt=""/>
                            </div>
                        </div>
                        </div>
                        <div className="bottom">
                            <div className="social-icon">
                                <ul>
                                    <li>
                                        <Link to={''}>
                                            <svg id="Group_5984" data-name="Group 5984"
                                                 xmlns="http://www.w3.org/2000/svg" width="32.064" height="32.064"
                                                 viewBox="0 0 32.064 32.064">
                                                <path id="Path_6999" data-name="Path 6999"
                                                      d="M16.032,0A16.032,16.032,0,1,1,0,16.032,16.032,16.032,0,0,1,16.032,0Z"
                                                      transform="translate(0 0)" fill="#fffefb"/>
                                                <rect id="Rectangle_2008" data-name="Rectangle 2008" width="4"
                                                      height="4" rx="2" transform="translate(14 14)" fill="#fff"
                                                      opacity="0"/>
                                                <path id="Path_26" data-name="Path 26"
                                                      d="M1013.278,3919.394v-1.157a2.52,2.52,0,0,1,.025-.4.785.785,0,0,1,.11-.281.45.45,0,0,1,.277-.191,2.009,2.009,0,0,1,.51-.053h1.156v-2.313h-1.849a3.007,3.007,0,0,0-2.305.762,3.206,3.206,0,0,0-.7,2.244v1.393h-1.385v2.313h1.384v6.712h2.778v-6.712h1.849l.244-2.313Z"
                                                      transform="translate(-996.29 -3905.678)" fill="#2d2d2d"/>
                                            </svg>

                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={''}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 viewBox="0 0 32 32">
                                                <g id="Group_13726" data-name="Group 13726"
                                                   transform="translate(-0.285)">
                                                    <rect id="Rectangle_1998" data-name="Rectangle 1998" width="32"
                                                          height="32" rx="16" transform="translate(0.285)"
                                                          fill="#fffefb"/>
                                                    <rect id="Rectangle_2008" data-name="Rectangle 2008" width="4"
                                                          height="4" rx="2" transform="translate(14.285 14)" fill="#fff"
                                                          opacity="0"/>
                                                    <path id="Path_5915" data-name="Path 5915"
                                                          d="M454.555,22.88a8.921,8.921,0,0,0,8.982-8.982q0-.205-.009-.408a6.427,6.427,0,0,0,1.575-1.634,6.3,6.3,0,0,1-1.813.5,3.168,3.168,0,0,0,1.388-1.746,6.328,6.328,0,0,1-2,.766,3.16,3.16,0,0,0-5.38,2.879,8.962,8.962,0,0,1-6.507-3.3,3.159,3.159,0,0,0,.977,4.214,3.135,3.135,0,0,1-1.43-.4c0,.013,0,.026,0,.04a3.158,3.158,0,0,0,2.532,3.095,3.154,3.154,0,0,1-1.425.054,3.16,3.16,0,0,0,2.949,2.193,6.334,6.334,0,0,1-3.921,1.351,6.409,6.409,0,0,1-.753-.044,8.936,8.936,0,0,0,4.839,1.418"
                                                          transform="translate(-440.738 -0.115)" fill="#2d2d2d"/>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={''}>
                                            <svg id="Group_13725" data-name="Group 13725"
                                                 xmlns="http://www.w3.org/2000/svg" width="32.064" height="32.064"
                                                 viewBox="0 0 32.064 32.064">
                                                <path id="Path_7000" data-name="Path 7000"
                                                      d="M16.032,0A16.032,16.032,0,1,1,0,16.032,16.032,16.032,0,0,1,16.032,0Z"
                                                      transform="translate(0)" fill="#fffefb"/>
                                                <rect id="Rectangle_2008" data-name="Rectangle 2008" width="3"
                                                      height="4" rx="1.5" transform="translate(14.57 14)" fill="#fff"
                                                      opacity="0"/>
                                                <g id="Group_13724" data-name="Group 13724"
                                                   transform="translate(8.978 8.978)">
                                                    <path id="Path_5912" data-name="Path 5912"
                                                          d="M7.173,1.293c1.915,0,2.142.007,2.9.042a3.967,3.967,0,0,1,1.332.247,2.222,2.222,0,0,1,.825.537,2.222,2.222,0,0,1,.537.825,3.972,3.972,0,0,1,.247,1.332c.034.756.042.983.042,2.9s-.007,2.142-.042,2.9a3.972,3.972,0,0,1-.247,1.332A2.375,2.375,0,0,1,11.4,12.765a3.967,3.967,0,0,1-1.332.247c-.756.034-.983.042-2.9.042s-2.142-.007-2.9-.042a3.967,3.967,0,0,1-1.332-.247,2.222,2.222,0,0,1-.825-.537,2.222,2.222,0,0,1-.537-.825,3.967,3.967,0,0,1-.247-1.332c-.034-.756-.042-.983-.042-2.9s.007-2.142.042-2.9a3.967,3.967,0,0,1,.247-1.332,2.222,2.222,0,0,1,.537-.825,2.222,2.222,0,0,1,.825-.537,3.967,3.967,0,0,1,1.332-.247c.756-.034.983-.042,2.9-.042M7.173,0C5.225,0,4.981.008,4.216.043A5.266,5.266,0,0,0,2.475.377,3.516,3.516,0,0,0,1.2,1.2,3.516,3.516,0,0,0,.377,2.475,5.266,5.266,0,0,0,.043,4.216C.008,4.981,0,5.225,0,7.173s.008,2.192.043,2.958a5.266,5.266,0,0,0,.333,1.741A3.516,3.516,0,0,0,1.2,13.143a3.518,3.518,0,0,0,1.271.827,5.266,5.266,0,0,0,1.741.333c.765.035,1.009.043,2.958.043s2.192-.008,2.958-.043a5.266,5.266,0,0,0,1.741-.333,3.668,3.668,0,0,0,2.1-2.1,5.266,5.266,0,0,0,.333-1.741c.035-.765.043-1.009.043-2.958s-.008-2.192-.043-2.958a5.266,5.266,0,0,0-.333-1.741A3.518,3.518,0,0,0,13.143,1.2,3.516,3.516,0,0,0,11.872.377,5.266,5.266,0,0,0,10.131.043C9.366.008,9.121,0,7.173,0"
                                                          fill="#2d2d2d"/>
                                                    <path id="Path_5913" data-name="Path 5913"
                                                          d="M24.359,20.676a3.684,3.684,0,1,0,3.683,3.683,3.684,3.684,0,0,0-3.683-3.683m0,6.075a2.391,2.391,0,1,1,2.391-2.391,2.391,2.391,0,0,1-2.391,2.391"
                                                          transform="translate(-17.186 -17.186)" fill="#2d2d2d"/>
                                                    <path id="Path_5914" data-name="Path 5914"
                                                          d="M61.809,15.574a.861.861,0,1,1-.861-.861.861.861,0,0,1,.861.861"
                                                          transform="translate(-49.945 -12.229)" fill="#2d2d2d"/>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={''}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 viewBox="0 0 32 32">
                                                <g id="Group_5985" data-name="Group 5985" transform="translate(0.145)">
                                                    <g id="Group_19209" data-name="Group 19209"
                                                       transform="translate(0 0)">
                                                        <rect id="Rectangle_1998" data-name="Rectangle 1998" width="32"
                                                              height="32" rx="16" transform="translate(-0.145)"
                                                              fill="#fffefb"/>
                                                        <rect id="Rectangle_2008" data-name="Rectangle 2008" width="4"
                                                              height="4" rx="2" transform="translate(13.855 14)"
                                                              fill="#fff" opacity="0"/>
                                                    </g>
                                                    <g id="Group_28" data-name="Group 28"
                                                       transform="translate(10.005 9.948)">
                                                        <path id="LinkedIn"
                                                              d="M1099.058,3923.038v4.248h-2.491v-3.963c0-1-.36-1.675-1.263-1.675a1.361,1.361,0,0,0-1.278.9,1.664,1.664,0,0,0-.083.6v4.137h-2.492s.033-6.713,0-7.409h2.492v1.051l-.016.023h.016v-.023a2.478,2.478,0,0,1,2.246-1.225C1097.829,3919.7,1099.058,3920.762,1099.058,3923.038Zm-10.214-6.732a1.285,1.285,0,1,0-.033,2.561h.017a1.286,1.286,0,1,0,.016-2.561Zm-1.262,10.98h2.491v-7.409h-2.491Z"
                                                              transform="translate(-1087.435 -3916.306)"
                                                              fill="#2d2d2d"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={''}>
                                            <div className="hover">
                                            <svg id="Group_5986" data-name="Group 5986"
                                                 xmlns="http://www.w3.org/2000/svg" width="32.064" height="32.064"
                                                 viewBox="0 0 32.064 32.064">
                                                <path id="Path_7001" data-name="Path 7001"
                                                      d="M16.032,0A16.032,16.032,0,1,1,0,16.032,16.032,16.032,0,0,1,16.032,0Z"
                                                      transform="translate(0 0)" fill="#fffefb"/>
                                                <rect id="Rectangle_2008" data-name="Rectangle 2008" width="4"
                                                      height="4" rx="2" transform="translate(14.14 14)" fill="#fff"
                                                      opacity="0"/>
                                                <g id="Group_31" data-name="Group 31"
                                                   transform="translate(9.638 11.172)">
                                                    <path id="Path_29" data-name="Path 29"
                                                          d="M1178.776,3919.591c-.462-.545-1.314-.768-2.942-.768h-5.908c-1.665,0-2.531.237-2.992.818a4.155,4.155,0,0,0-.448,2.556v2.2c0,2.238.532,3.375,3.44,3.375h5.908a3.737,3.737,0,0,0,2.7-.678,3.539,3.539,0,0,0,.741-2.7v-2.2A4.052,4.052,0,0,0,1178.776,3919.591Zm-4.08,4.012-2.683,1.395a.412.412,0,0,1-.6-.364v-2.78a.411.411,0,0,1,.2-.351.417.417,0,0,1,.4-.014l2.683,1.386a.41.41,0,0,1,0,.729Z"
                                                          transform="translate(-1166.487 -3918.823)" fill="#2d2d2d"/>
                                                </g>
                                            </svg>
                                            </div>

                                        </Link>
                                    </li>


                                </ul>
                            </div>
                            <p>© 2023 Kopotakkho Homes Limited. All Rights Reserved..</p>
                            <p><a href="https://dcastalia.com" target={'_blank'}>Designed & Developed by
                                Dcastalia</a></p>

                        </div>
                    </div>


                </div>

            </StyledMobileMenu>
        </>

    );
};

const StyledMenu = styled.section`
  height: 90px;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;


  &.menu-is-open {
    transform: none !important;
  }

  @media (max-width: 989px) {
    display: none;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 98px;
    z-index: 5;
  }

  .main-menu__contact {
    height: fit-content;

    &__drop {
      width: 152px;
      height: 40px;
      background-color: #222222;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;


      p {
        font-size: 20px;
        color: #ffffff;

        svg {
          margin-left: 10px;
        }
      }
    }

    &__list {
      background-color: #F2EEE8;
      padding: 0px 30px 0 30px;
      width: 260px;
      display: none;
      height: 0;
      overflow: hidden;


      a {
        color: red;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;


        span {
          display: inline-flex;
          height: 24px;
          width: 24px;
          background: #222;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          span:after {
            height: 0;
            width: 0;
          }
        }
      }

      ul {
        li {
          display: flex;
          padding-bottom: 17px;
          margin-bottom: 18px;
          border-bottom: 1px solid rgba(178, 168, 159, 0.20);
          flex-wrap: wrap;

          a {
            color: red;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
            text-transform: lowercase;

            &:hover {
              letter-spacing: 1px;
              color: red;

            }
          }

          span {
            font-size: 12px;
            line-height: 24px;
            color: #B2A89F;
            width: 100%;
            padding-bottom: 2px;

          }

          &:nth-last-of-type(1) {
            padding: 0;
            margin: 0;
            border: none;
          }
        }
      }
    }

  }

  .main-menu__logo {
    svg {
      transition: all .3s ease;
      height: auto;
    }
  }

  .main-menu__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      margin-top: 1px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
      color: #FFFEFB;
      line-height: 24px;
      letter-spacing: 2px;
      transition: all .6s cubic-bezier(0.4, 0, 0, 1) 0s;;

    }
    
    &__inner {
      padding: 0px 0 5px 0;
      cursor: pointer;
      height: fit-content;
      transition: padding .4s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      
      p{
        &:hover{
          opacity: .5;
        }
      }
      
      .wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 22px;
        margin-left: 20px;
        width: 30px;

        &:hover {

          .line {
            width: 20px;
            color: red;

            &:nth-of-type(2) {
              width: 25px;
            }

            &:nth-of-type(1) {
              width: 15px;
            }

            &:nth-of-type(3) {
              width: 15px;
            }
          }
        }
      }

      .line {
        height: 2px;
        background-color: white;
        border-radius: 5px;
        transform-origin: left;
        transition: width .6s cubic-bezier(0.4, 0, 0, 1) 0s;
        width: 30px;
      }
    }

  }

  .main-menu__items {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;
    background-color: #5C5550;


    .d-flex {
      width: 100%;
    }

    .shadows {
      position: absolute;
      bottom: -40%;
      right: -35%;
      z-index: 4;
      transform: translateX(100%);
    }

    .shadows-left {
      width: fit-content;
      position: absolute;
      bottom: 0;
      left: -130px;
      z-index: 4;
    }
    
    

    &__img {
      position: relative;
      height: 100vh;
      overflow: hidden;
      background: #fff;
      
      li {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        //position: absolute;
        //height: 100%;
        //top: 0;
        //right: 0;
        //bottom: 0;
        //width: 100%;
        //opacity: 1;
        //transform: scale(.8);
        //transition: all .1s cubic-bezier(0.4, 0, 0, 1);
        //transition: transform .9s cubic-bezier(0.4, 0, 0, 1);
        //transition-delay: 1s;
        //z-index: 1;
        //transform: translate3d(100%, 0, 0);
        img {
          //transform: translateX(100%);
          //transition: all .6s cubic-bezier(0.4, 0, 0, 1);
          transition: all .9s ease;
          opacity: 0;
          
        }
      

        //&:after {
        //  content: '';
        //  position: absolute;
        //  height: 100%;
        //  width: 100%;
        //  background-color: black;
        //  //opacity: .6;
        //  left: 0;
        //  top: 0;
        //  z-index: 3;
        //  transition: all 3s ease;
        //  transition-delay: .2s;
        //}

        &.active {
          //transform: none;
          opacity: 1;
  
          //width: 100%;
          //transition-delay: .6s !important;
          z-index: 3;
          //transition: transform .9s cubic-bezier(0.4, 0, 0, 1);
          //transition: width .6s cubic-bezier(0.4, 0, 0, 1);
          //transition: width .8s cubic-bezier(0.4, 0, 0, 1);
          //transform: scale(1);

          img {
            //transform: none;
            //transition-delay: .4s;
            opacity: 1;
            //transform: translateX(0%);
          }

          &:after {
            //width: 0%;
            //transition-delay: .3s;
            //animation: mymove 1s cubic-bezier(0.4, 0, 0, 1);
          }
        }
      }
    }

    &__list {
      background-color: #2D2D2D;
      min-height: 100%;
      padding-top: 160px;
      transform: translateY(40px);
      opacity: 0;

      .menu-close {
        height: 55px;
        width: 55px;
        position: absolute;
        top: 40px;
        right: 30px;
        border: 3px solid #F7F3F0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        cursor: pointer;
        z-index: 4;

        &:after {
          //z-index: 2;
          background-color: #F7F3F0;
          //border: 2px solid #F7F3F0;
          border-radius: 50%;
        }

        svg {
          position: relative;
          z-index: 99;

          line {

          }
        }

        &:hover {
          svg {
            line {
              stroke: #2D2D2D;
            }
          }
        }
      }

      ul {
        li {

          a {
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
            text-transform: none;
            color: #F7F3F0;
            position: relative;
            padding-bottom: 6px;
            display: inline-block;
            margin-bottom: 24px;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              height: 1px;
              background-color: #F7F3F0;
              bottom: 0;
              width: 0;
              transition: 0.7s all ease;
            }

            &:hover {
              &:before {
                width: 100%;
              }

              color: #F7F3F0 !important;
            }
          }

          &.active {
            a {
              color: #F7F3F0 !important;

              pointer-events: none;

              &:before {
                width: 100%;
              }
            }
          }
        }
      }

      &__bottom {
        position: absolute;
        bottom: 50px;
        background-color: transparent;
        width: 100%;
        left: calc(8.333333% + 15px);

        .social-icon {
          ul {
            display: flex;

            li {
              margin-right: 20px;

              a {
                margin-bottom: 20px;

                &:hover {
                  &:before {
                    width: 0;
                  }
                }

              }
            }
          }
        }

        .video {

          .hover:after {
            background-color: #fff;
          }

          span {
            display: flex;
            height: 30px;
            width: 30px;
            border: 1px solid red
          }
        ;
          align-items: center;
          justify-content: center;
          background-color: #222;
          border-radius: 50%;
          margin-right: 13px;
          margin-top: -4px;

          path {

          }

        }

        p {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 20px;
          color: #AC8B7C !important;
          font-family: "Suisse Int'l";
          font-weight: 300;

          a {
            font-size: 12px;
            line-height: 20px;
            color: #AC8B7C !important;
            font-family: "Suisse Int'l";
            font-weight: 300;
          }
        }
      }

      .social {
        margin-top: 30px;

        ul {
          display: flex;

          li {
            a {
              margin: 0 30px 0 0;
              height: 45px;
              width: 45px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #222;
              padding: 0;

              &:after {
                //display: none;
              }
            }

            &:nth-last-of-type(1) a {
              margin: 0;
            }
          }
        }
      }

      .leaf-list__wrap {
        position: relative;

        .right-leaf {
          width: 200px;
          height: 150px;
          position: absolute;
          top: 0;
          transform: translate(0%, 150%);
        }
      }

    }
  }


  .mute-toggle {
    position: absolute;
    left: 200px;
    top: 10px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    line {
      display: none;
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }
  
  .socila-icon__single{
    background: white;
    height: 32px;
    width: 32px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }


  @media (max-height: 750px) {
    //.main-menu__items__list__bottom {
    //  left: 60px;
    //}
    .main-menu__items__list {
      padding-top: 75px;


    }
  }

`;

//----- mobile menu
const StyledMobileMenu = styled.section`
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
  transition-delay: .3s;
  background: rgba(0, 0, 0, 0.3);
  height: 90px;

  .menu-close {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 20px;
    right: 15px;
    border: 3px solid #F7F3F0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    z-index: 4;
    margin-bottom: 60px;

    &:after {
      //z-index: 2;
      background-color: #F7F3F0;
      //border: 2px solid #F7F3F0;
      border-radius: 50%;
    }

    svg {
      position: relative;
      z-index: 99;

      line {

      }
    }

    &:hover {
      svg {
        line {
          stroke: #2D2D2D;
        }
      }
    }
  }

  .mute-toggle-mobile {
    position: absolute;
    left: 15px;
    top: 12px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    path {
      fill: red;
    }

    line {
      display: none;
      stroke: red;
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }

  &.menu-is-open {
    transform: none !important;

    #open-click {
      opacity: 0;
    }
  }

  @media (min-width: 989px) {
    display: none;
  }

  #open-click {

  }

  .main-menu-mobile__bar {

    display: flex;
    justify-content: space-between;
    //align-items: center;

    padding: 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    //background-color: rgba(34, 34, 34, 0.30);
    z-index: 999;
    transition: all .4s ease;
    height: 90px;

    &__logo {
      position: relative;
      margin: auto;
      width: fit-content;

      img {
        height: 50px;
      }

    }

    &__hamburger {

      width: 100%;
      padding-top: 0;
      height: 100%;
      align-items: center;

      p {
        font-size: 16px;
        line-height: 24px;
        color: #FFFEFB;
        font-family: Kudryashev Display;
        margin-right: 20px;
      }

      svg {
        height: 20px;
        opacity: 1;
        transition: 1s all ease;
      }
    }


  }


  .main-menu-mobile__items {
    padding: 0 0px;
    position: relative;
    transform-origin: top left;
    transition: all .3s ease-out;
    height: calc(100vh);
    overflow-y: auto;
    //padding-top: 90px;
    overflow-x: hidden;
    background-color: transparent;
    width: 100vw;
    transform: translateX(100%);
    display: none;
    z-index: 999;
   
    &__head {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: calc(100vw);
      top: 0px;
      left: 0px;
      height: 90px;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      background: transparent;
      z-index: 9999;


      #close-click {

      }

      .sub-menu-back {
        display: none;
      }
    }

    &__body {
      background: #2D2D2D;
      height: 100%;
      width: 100vw;
      padding: 0 20px;
      position: relative;
      right: 0;
      margin: 0 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &__list-wrap{
        margin-top: 60px;
      }
      ul {
        width: 100%;

        &.menu-list {
          //height: calc(100vh - 300px);
          //overflow-y: auto;
          //width: 100%;
          //height: 100%;
          width: 100%;

          a {
            width: 100%;
            display: flex;
          }
        }

        .main-item {
          ul {
            li {
              a {
                pointer-events: auto !important;
              }

              &:nth-of-type(1) {
                a {
                  position: relative;
                  display: flex;


                  &:after {
                    content: '';
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    background-color: red;
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 100%;
                    //background-image: url(árrow);
                    background-repeat: no-repeat;
                    background-position: center;
                  }
                }
              }


            }
          }
        }


        li {
          text-transform: capitalize;
          display: block;
          width: 100%;


          a {
            color: #F7F3F0;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
          }

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 24px;

          }

          &.active {
            a {
              border-bottom: 1px solid #F7F3F0;
              width: fit-content;
              pointer-events: none;
            }
          }

          //sub menus
          ul {
            left: 0;
            list-style: none;
            margin: 0;
            position: absolute;
            top: 113px;
            padding: 0 15px;
            //height: 100vh;
            opacity: 0;
            transform: translate3d(100vw, 0, 0);
            transition: all .3s ease;
            //padding-bottom: 40px;
            width: 100vw;
            padding-bottom: 25px;
            height: auto !important;
            overflow: hidden !important;

            li {
              width: auto !important;
              background-color: transparent !important;
              height: auto !important;
              display: block !important;
              text-align: left !important;

              &:nth-of-type(1) {
                margin-bottom: 80px !important;
              }

              &:not(:nth-of-type(1)) {
                a {

                  text-transform: capitalize;
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  font-size: 30px;
                  line-height: 30px;

                  span {
                    min-width: 100%;
                    font-size: 12px;
                    line-height: 23px;
                    display: block;
                    margin-bottom: 13px;
                    color: #b2a89f;
                  }
                }
              }

            }
          }

          &.active {
            ul {
              opacity: 1;
              z-index: 2;
            }

          }

        }
      }


      &.active {
        transform: translate3d(-100vw, 0, 0) !important;

        .main-menu-mobile__items__head {
          transform: translate3d(100vw, 0, 0) !important;

          .sub-menu-back {
            display: block;
          }

          a {
            display: none;
          }
        }
      }

      .bottom {
        .social-icon {
          ul {
            display: flex;

            li {
              width: unset;
              margin-right: 20px;

            }
          }


        }

        p {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 20px;
          color: #AC8B7C !important;
          font-family: "Suisse Int'l";
          font-weight: 300;

          a {
            font-size: 12px;
            line-height: 20px;
            color: #AC8B7C !important;
            font-family: "Suisse Int'l";
            font-weight: 300;
          }
        }

      }

      .leaf-list__wrap {
        margin-top: 80px;
        position: relative;

        .right-leaf {
          img{
            height: 120px;
            width: 150px;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(0%, 100%);
          }
          
        }
      }

    }

  }



`;

export default MyComponent;
