import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";

const AtGlance = ({data,padding}) => {

    console.log(data);

    return (
        <StyledAtGlance id={'second'} className={'at-glance pb-200'}>
            <Container>
                <Row>
                    <Col md={5} className='pr-0 at-glance__image '>
                        <div className="">
                            <div className="at-glance__img  ">
                                <Img src={data?.image? data?.image : '/images/dynamic/project/details/at.jpg'}/>
                            </div>
                        </div>

                    </Col>
                    <Col md={{span:6,offset:1}} className='at-glance__content pl-0'>
                        <div className='at-glance__content__title'>
                            <Title small_text_color={'#2D2D2D'} margin={'0 0 50px'}
                                   fontSize={'40'} lineHeight={'48'} fontWeight={'400'}
                                   text={data?.title}/>
                            <div className="fade-up">
                                {ReactHtmlParser(data?.description)}
                                {/*<table>*/}
                                {/*    <tbody>*/}
                                {/*    <tr>*/}
                                {/*        <td>Address</td>*/}
                                {/*        <td>Bashundhara, R/A</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Size</td>*/}
                                {/*        <td>2575-3250 sq ft.</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Land Size</td>*/}
                                {/*        <td>20 Katha</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Property Orientation</td>*/}
                                {/*        <td>North</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Number of Apartments</td>*/}
                                {/*        <td>36</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Number of Basements</td>*/}
                                {/*        <td>02</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Road Width</td>*/}
                                {/*        <td>100 ft.</td>*/}
                                {/*    </tr>*/}
                                {/*    <tr>*/}
                                {/*        <td>Architectural Consultant</td>*/}
                                {/*        <td>VolumeZero</td>*/}
                                {/*    </tr>*/}

                                {/*    </tbody>*/}
                                {/*</table>*/}
                            </div>

                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledAtGlance>
    );
};
const StyledAtGlance = styled.section`
  overflow: hidden;

  .at-glance {
    &__img {
      position: relative;
      padding-top: calc(600 / 500 * 100%);
    }

    &__content {
      color: #252E47;
      display: flex;

      &__title {
        width: 100%;

        h3 {
          padding: 0;
        }
        

        table {
          tr {
            display: flex;
            width: 100%;
            border-bottom: 1px solid #AC8B7C;
            padding-top: 15px;
            padding-bottom: 15px;
            //justify-content: space-between;

            &:first-child{
              padding-top: 0;
            }
            &:last-child {
              //border-bottom: none;
              //padding-bottom: 0;
              td {
                padding-bottom: 0;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;

          }

          td {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #2D2D2D;
            font-family: "Suisse Int'l";

            &:first-child {
              font-weight: 400;
              flex: 0 0 35%;
              padding-left: 0px;
              color: rgba(34, 34, 34, 0.8);

            }
            &:last-child {
              padding-left: 100px;
            }

          }
        }
      }
    }
  }
  
  
  
  @media(min-width: 1550px){
    .title{
      h2{
        font-size: 45px;
        line-height: 50px;
      }
    }
  }
  @media(max-width: 767px){
    .title{
      h2{
        
      }
    }
    .at-glance{
      &__image{
        padding-right: 15px !important;
      }
      &__content{
        margin-top: 30px;
        padding-left: 15px !important;
        table{
          tr{
            flex-direction: row;
            padding-bottom: 15px;
            td{
              &:first-child{
                font-size: 14px;
                margin-bottom: 0px;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

`;
export default AtGlance;