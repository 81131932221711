import React, {useEffect} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";

const Banner = ({data}) => {
    const interleaveOffset = 0.5;
    // const  sliderData = data?.data?.images?.list;


    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };

    const pagination = document.querySelector('.swiper-pagination-horizontal');
    if (pagination) {
        pagination.classList.add('container');
        // console.log('Updated HTML:', pagination);
    } else {
        // console.log('Element with class "swiper-pagination" not found.');
    }


    // handle slider progress
    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
        }
    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".slide-inner")
                .style.transition = `${speed}ms`;
        }
    };


    // remove extra height from mobile
    useEffect(() => {

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);


        window.addEventListener('load', function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });
        window.addEventListener('resize', function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });

    }, []);
    return (
        <StyledBanner className={'home-banner'}>
            <Container>
                <div className="sweeper-nav">
                    <ul className={'navigation'}>
                        <li id={'parenting-prev'} className={'prev'}>

                            <svg id="Component_29_5" data-name="Component 29 – 5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle id="Ellipse_462" data-name="Ellipse 462" cx="12.5" cy="12.5" r="12.5" fill="none" stroke="#fff" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_302" data-name="Mask Group 302" clip-path="url(#clip-path)">
                                    <g id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                                        <circle cx="12.5" cy="12.5" r="12" fill="none"/>
                                    </g>
                                    <g id="Group_19850" data-name="Group 19850" transform="translate(-619 -716.5)">
                                        <g id="Group_19216" data-name="Group 19216" transform="translate(627 725.5)">
                                            <line id="Line_3882" data-name="Line 3882" x1="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883" data-name="Line 3883" x2="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377" data-name="Line 12377" x2="10" transform="translate(627.5 728.5)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                    <g id="Group_19851" data-name="Group 19851" transform="translate(-599 -716.5)">
                                        <g id="Group_19216-2" data-name="Group 19216" transform="translate(627 725.5)">
                                            <line id="Line_3882-2" data-name="Line 3882" x1="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883-2" data-name="Line 3883" x2="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377-2" data-name="Line 12377" x2="10" transform="translate(627.5 728.5)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li id={'parenting-next'} className={'next'}>
                            <svg id="Component_30_4" data-name="Component 30 – 4" xmlns="http://www.w3.org/2000/svg"  width="25" height="25" viewBox="0 0 25 25">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle id="Ellipse_462" data-name="Ellipse 462" cx="12.5" cy="12.5" r="12.5" fill="none" stroke="#fff" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_302" data-name="Mask Group 302" clip-path="url(#clip-path)">
                                    <g id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                                        <circle cx="12.5" cy="12.5" r="12" fill="none"/>
                                    </g>
                                    <g id="Group_19850" data-name="Group 19850" transform="translate(-12 9)">
                                        <g id="Group_19216" data-name="Group 19216" transform="translate(7.346)">
                                            <line id="Line_3882" data-name="Line 3882" x2="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883" data-name="Line 3883" x1="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377" data-name="Line 12377" x1="10" transform="translate(0 3)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                    <g id="Group_19851" data-name="Group 19851" transform="translate(8 9)">
                                        <g id="Group_19216-2" data-name="Group 19216" transform="translate(7.346)">
                                            <line id="Line_3882-2" data-name="Line 3882" x2="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883-2" data-name="Line 3883" x1="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377-2" data-name="Line 12377" x1="10" transform="translate(0 3)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>


                        </li>
                    </ul>
                </div>

            </Container>


            {
                data?.list && data?.list?.length>0 &&
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={0}
                    autoplay={true}
                    speed='1000'
                    onProgress={handleProgress}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    loop={true}
                    watchSlidesProgress={true}
                    mousewheelControl={true}
                    keyboardControl={true}
                    // navigation={true}
                    navigation={{
                        prevEl: '#parenting-prev',
                        nextEl: '#parenting-next',
                    }}
                    pagination={true}
                    className="mySwiper"
                >
                    {
                        data?.list && data?.list?.length>0 &&
                        data?.list.map((element,index)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <div className="slide-inner">
                                        <Img src={element?.image? element?.image : '/images/dynamic/home/banner/1.jpg'}/>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <div className="slide-inner__info d-flex">
                                                        {/*<h2>{ReactHtmlParser('The sign of </br> <span>Excellence</span>')}</h2>*/}
                                                        {
                                                            element?.title &&  <h1 className={'split-up'} >{ReactHtmlParser(element?.title)}</h1>
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Container>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }



                </Swiper>

            }

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  //height: calc(100vh - 100px);
  //height: calc(var(--vh, 1vh) * 100 - 100px);

  position: relative;
  overflow: hidden;
  //margin-top: 100px;
  .home-banner {
    .container {
      position: relative;
    }

  }

  .sweeper-nav {
    position: absolute;
    right: 8%;
    top: 90%;
    z-index: 999;

    ul {
      display: flex;

      li {
        margin-left: 10px;
        cursor: pointer;
        //background: red;
      }
    }
    .next {
      #Group_19850 {
        transition: .2s ease-in-out;
      }

      #Group_19851 {
        transition: .2s ease-in-out;
      }
      transition: .2s ease-in-out;
      &:hover {
        svg {
          #Group_19850 {
            transform: translate(8px, 9px);
            transition: .2s ease-in-out;
          }

          #Group_19851 {
            transform: translate(55px, 9px);
            transition: .2s ease-in-out;
          }

        }
      }


    }

    .prev {
      #Group_19850 {
        transition: .2s ease-in-out;
      }

      #Group_19851 {
        transition: .2s ease-in-out;
      }
      transition: .2s ease-in-out;
      &:hover {
        svg {
          #Group_19850 {
            transform: translate(-800px, -716.5px);
            transition: .2s ease-in-out;
          }

          #Group_19851 {
            transform: translate(-620px, -716.5px);
            transition: .2s ease-in-out;
          }

        }
      }


    }


  }



  .icon {
    padding: 12px 16px;
    margin-right: 20px;
  }

  @keyframes bounce {
    from {
      transition: var(--transition);
      transform: translate3d(0, 0, 0);
    }

    to {
      transition: var(--transition);
      transform: translate3d(0, 10px, 0);
    }
  }

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: 60%;
      transform: translateY(-50%);
      z-index: 2;

    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;

      h1 {
        font-size: 60px;
        line-height: 85px;
        color: #FFFFFF;
        font-family: Kudryashev Headline;

        span {
          margin-left: 150px;
        }
      }

      .dc-btn {
        position: relative;
        transform: translateY(400px);
        transition-delay: 0.5s;
        transition: 3s all cubic-bezier(0.4, 0, 0, 1);

      }
    }
  }

  .swiper-slide-active {
    .slide-inner {
      &__info {
        h1 {
          transform: translateY(0px);
        }

        .dc-btn {
          transform: translateY(0px);
        }
      }

    }
  }

  .swiper-pagination-bullet-active {
    font-size: 30px;
  }

  swiper-pagination-bullets {
    //display: flex;
    //background-color: yellow;

  }

  .swiper-pagination-bullet {
    display: block !important;
    background-color: white;
    opacity: 1 !important;
    margin-bottom: 10px !important;
  }

  .swiper-pagination-lock {
    display: block !important;
  }

  .swiper {
    position: relative;
  }

  .swiper-pagination {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40%;
    left: 90%;
  }

  .swiper-pagination-bullet-active {
    outline: 1px solid white;
    background-color: transparent;
    opacity: 0;
  }

  //scroll to next section
  .scrollTo {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 10;
    animation: bounce 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;

    circle {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
    }

    #Ellipse_400 {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
      fill: ${hover};
      stroke: ${hover}
    }

    &:hover {
      circle {
        stroke: ${hover}
      }

      path {
        stroke: rgba(255, 255, 255, 1);
      }

      #Ellipse_400 {
        r: 19.5px;

      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h1 {
          font-size: 80px;
          line-height: 115px;
        }

        .dc-btn {
          a {
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    //border-bottom-right-radius: 30px;
    .icon {
      margin-right: 10px;
    }

    .slide-inner {
      .container {
        top: unset;
        transform: unset;
        bottom: 150px;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h1 {
          font-size: 34px;
          line-height: 40px;

          br {
            display: none;
          }

          span {
            margin-left: 0px;
            //padding-left: offset;
          }

        }


        .dc-btn {
          margin-top: 30px;
        }
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      bottom: 30px;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
    .sweeper-nav{
      left: 0;
      top: 85%;
      right: unset;
      transform: translate(40px , 15px);
    }
  }
`;

export default Banner;
